import { _auth } from "./_auth";
import { useHistory } from "react-router-dom";
import { features } from "./_features";

// const profile = _auth.getUserProfile();
// const showHome = profile.showHome;

import { _stripe } from "./_stripe";
import { Preferences } from "./_user_preferences";
// const history = useHistory();
function GetHistory() {
  const history = useHistory();
  return history;
}
const profile = _auth.getUserProfile();
export const _navigation = {
  getHeaderLinks(showHome) {
    console.info("NAVIGATION SHOW HOME", showHome);
    // console.info("NAVIGATION SHOW HOME", showHome);
    const navigation = [
      {
        name: "Home",
        href: "/home",
        current: true,
        icon: "home",
        iconify: "heroicons-outline:home",
        show: true, //showHome == true ? true : false,
      },
      {
        name: "My Dashboard",
        href: "/dashboard",
        current: true,
        icon: "dashboard",
        iconify: "mdi:graph-box-outline",
        show: true,
      },
      {
        name: "My Team",
        href: "/dashboard1",
        current: true,
        icon: "employees",
        iconify: "material-symbols:groups-rounded",
        show: false,
      },
      {
        name: "Competencies",
        href: "/competencies",
        current: false,
        show: true,
        icon: "assessment",
        iconify: "fluent:contact-card-ribbon-24-regular", //"fluent:reward-12-regular", // "material-symbols:featured-play-list-outline-rounded",
        status: "prod",
      },

      {
        name: "Impediments",
        href: "/impediments",
        current: false,
        show: true,
        icon: "marketplace",
        iconify: "fluent:slow-mode-24-regular",
        status: "prod",
      },

      // {
      //   name: "Learning",
      //   href: "/learninghub",
      //   current: false,
      //   icon: "learning",
      //   show: false,
      //   status: "prod",
      // },

      // {
      //   name: "Assessments",
      //   href: "/assessmenthub",
      //   current: false,
      //   icon: "assessment",
      //   show: false,
      //   status: "prod",
      // },

      {
        name: "Assessments",
        href: "/assessments",
        current: false,
        icon: "assessment",
        iconify: "ph:exam",
        show: false,
        status: "prod",
      },
      {
        name: "Action Plans",
        href: "/actionplans",
        current: false,
        icon: "assessment",
        iconify: "icons8:spy",
        show: false,
        status: "prod",
      },
      {
        name: "My Plan",
        href: "/myplan",
        current: false,
        icon: "assessment",
        iconify: "icon-park-outline:plan",
        show:
          profile && profile.sysRole && profile.sysRole == "Super Admin"
            ? true
            : false,
        status: "prod",
      },
      // {
      //   name: "Employees",
      //   href: "/employees",
      //   current: false,
      //   icon: "employees",
      //   show: true, // features.isEntitled("Employees"),
      //   status: features.getStatus("Employees"),
      // },

      // {
      //   name: "Marketplace",
      //   href: "/marketplace",
      //   current: false,
      //   icon: "marketplace",
      //   show: false, // features.isEntitled("Marketplace"),
      //   status: features.getStatus("Marketplace"),
      // },
      // {
      //   name: "Knowledge Center",
      //   href: "/learn",
      //   current: false,
      //   icon: "learning",
      //   show: false, // features.isEntitled("Knowledge Center"),
      //   status: features.getStatus("Knowledge Center"),
      // },
      // {
      //   name: "Community",
      //   href: "/community",
      //   current: false,
      //   icon: "community",
      //   show: false,
      //   status: "coming soon",
      // },
      // {
      //   name: "Education",
      //   href: "/analytics",
      //   current: false,
      //   icon: "learning",
      //   show: false,
      //   status: "coming soon",
      // },
      // {
      //   name: "Get Help",
      //   href: "/contact",
      //   current: false,
      //   icon: "support",
      //   show: false,
      //   status: "",
      // },
    ];
    return navigation;
  },
  getConsumerLinks(showHome) {
    console.info("NAVIGATION SHOW HOME", showHome);
    // console.info("NAVIGATION SHOW HOME", showHome);
    const navigation = [
      {
        name: "Home",
        href: "/home",
        current: true,
        icon: "home",
        iconify: "heroicons-outline:home",
        show: true, //showHome == true ? true : false,
      },
      {
        name: "Dashboard",
        href: "/dashboard",
        current: true,
        icon: "dashboard",
        iconify:
          "streamline:interface-content-chart-product-data-analysis-analytics-graph-line-business-board-chart",
        show: true,
      },
      {
        name: "Projects",
        href: "/projects",
        current: true,
        icon: "employees",
        iconify: "mdi:folders-outline",
        show: true,
        status: "prod",
      },
      {
        name: "Clients",
        href: "/client-list",
        current: true,
        icon: "employees",
        show: false,
        status: "prod",
      },
      {
        name: "Marketplace",
        href: "/provider-directory",
        current: true,
        icon: "marketplace",
        iconify: "mingcute:contacts-2-line",
        show: true,
        status: "prod",
      },

      {
        name: "History",
        href: "/audit",
        current: false,
        icon: "assessment",
        iconify: "icon-park-outline:history-query",
        show: true,
        status: "prod",
      },
    ];
    return navigation;
  },
  getBrokerLinks(showHome) {
    console.info("NAVIGATION SHOW HOME", showHome);
    // console.info("NAVIGATION SHOW HOME", showHome);
    const navigation = [
      {
        name: "Home",
        href: "/home",
        current: true,
        icon: "home",
        iconify: "heroicons-outline:home",
        show: true, //showHome == true ? true : false,
      },
      {
        name: "My Dashboard",
        href: "/dashboard",
        current: true,
        icon: "dashboard",
        show: true,
      },
      {
        name: "Projects",
        href: "/projects",
        current: true,
        icon: "employees",
        show: true,
        status: "prod",
      },
      {
        name: "Clients",
        href: "/client-list",
        current: true,
        icon: "employees",
        show: false,
        status: "prod",
      },
      {
        name: "Marketplace",
        href: "/provider-directory",
        current: true,
        icon: "marketplace",
        show: true,
        status: "prod",
      },

      {
        name: "History",
        href: "/audit",
        current: false,
        icon: "assessment",
        iconify: "icons8:spy",
        show: false,
        status: "prod",
      },
    ];
    return navigation;
  },
  getAdminLinks() {
    // console.info("NAVIGATION SHOW HOME", showHome);
    const navigation = [
      {
        name: "Home",
        href: "/home",
        current: true,
        icon: "home",
        iconify: "heroicons-outline:home",
        show: false,
      },
      {
        name: "Global Dashboard",
        href: "/dashboard",
        current: true,
        icon: "dashboard",
        iconify: "mdi:graph-box-outline",
        show: true,
      },

      {
        name: "Teams",
        href: "/teams",
        current: false,
        show: true,
        icon: "assessment",
        iconify: "ant-design:team-outlined",
        status: "prod",
      },
      {
        name: "Competencies",
        href: "/competencies",
        current: false,
        show: true,
        icon: "assessment",
        iconify: "fluent:contact-card-ribbon-24-regular", //"fluent:reward-12-regular", // "material-symbols:featured-play-list-outline-rounded",

        status: "prod",
      },

      {
        name: "Blockers",
        href: "/impediments",
        current: false,
        show: true,
        icon: "marketplace",
        iconify: "fluent:slow-mode-24-regular",
        status: "prod",
      },

      {
        name: "Assessments",
        href: "/assessments",
        current: false,
        icon: "assessment",
        iconify: "ph:exam",
        show: true,
        status: "prod",
      },
      {
        name: "My Plan",
        href: "/myplan",
        current: false,
        icon: "assessment",
        iconify: "icon-park-outline:plan",
        show:
          profile && profile.sysRole && profile.sysRole == "Super Admin"
            ? true
            : false,
        status: "prod",
      },
      {
        name: "Audit",
        href: "/audit",
        current: false,
        icon: "assessment",
        iconify: "icons8:spy",
        show: true,
        status: "prod",
      },
    ];
    return navigation;
  },
  getVendorLinks(role) {
    const navigation = [
      {
        name: "Home",
        href: "/home",
        current: true,
        icon: "home",
        iconify: "heroicons-outline:home",
        show: true, //showHome == true ? true : false,
        // status: "coming soon",
      },
      {
        name: "Dashboard",
        href: "/dashboard",
        current: true,
        icon: "dashboard",
        iconify: "radix-icons:dashboard",
        show: true,
        status: role !== "Super Admin" && "coming soon",
      },

      {
        name: "Bid Requests",
        href: "/vendor/projects",
        current: false,
        icon: "dashboard",
        iconify: "solar:money-bag-linear",
        // iconify: "icons8:spy",
        show: true,
        status: "beta",
      },
      // {
      //   name: "Clients",
      //   href: "/client-list",
      //   current: true,
      //   icon: "employees",
      //   show: false,
      //   status: "prod",
      // },
      // {
      //   name: "Marketplace",
      //   href: "/provider-directory",
      //   current: true,
      //   icon: "marketplace",
      //   show: true,
      //   status: "beta",
      // },

      // {
      //   name: "History",
      //   href: "/audit",
      //   current: false,
      //   icon: "assessment",
      //   iconify: "icons8:spy",
      //   show: true,
      //   status: "prod",
      // },
    ];
    return navigation;
  },
  getNavLinksByRole(role) {
    console.log("GetNavLinksByRole", role);
    if (role == "Administrator" || role == "Super Admin") {
      return this.getAdminLinks();
    }
    if (role == "Manager" || role == "Collaborator") {
      return this.getHeaderLinks();
    }
    if (role == "Observer") {
      return this.getAdminLinks();
    }
    return this.getHeaderLinks();
  },
  getNavLinksByTenantType(tenantType, role) {
    if (tenantType == "admin") {
      return this.getAdminLinks();
    }
    if (tenantType == "consumer") {
      return this.getConsumerLinks();
    }
    if (tenantType == "broker") {
      return this.getConsumerLinks();
    }
    if (tenantType == "vendor") {
      return this.getVendorLinks(role);
    }

    return this.getHeaderLinks();
  },
  vendorHomeLinks() {
    let options = [
      {
        id: "1",
        status: "beta",
        title: "Set up your profile",

        subTitle: "Set up your profile to start receiving bid requests",
        rightText: "",
        path: "/settings/account",
        // handleClick: () => {
        //   console.log("onclick called set up profile");
        //   GetHistory().push("/settings/account");
        // },
      },
      {
        id: "2",
        status: "beta",
        title: "Subscribe to the marketplace",
        subTitle: "Subscribe to the marketplace so people can find you",
        rightText: "",
        handleClick: (e) => {
          console.log("onclick called", e);
          // props.handleOpenAssessmentModalFromHome(e);
        },
      },
      {
        id: "3",
        status: "beta",
        title: "Subscribe to the Featured Vendors list",
        subTitle:
          "Subscribe to the Featured Vendors list so people can find you",
        rightText: "",
        handleClick: (e) => {
          // props.handleOpenContactModal(e);
        },
      },
    ];
    return options;
  },
  consumerHomeLinks() {
    let options = [
      {
        id: "1",
        status: "Active",
        title: "Invite your team",

        subTitle: "Invite your team to join your organization",
        rightText: "",
        handleClick: () => {
          GetHistory().push("/settings/account");
        },
      },
      {
        id: "2",
        status: "Active",
        title: "Create a project",
        subTitle: "Create a project to start your bid requests",
        rightText: "",
        handleClick: (e) => {
          console.log("onclick called", e);
          // props.handleOpenAssessmentModalFromHome(e);
        },
      },
      {
        id: "3",
        status: "Active",
        title: "Get help",
        subTitle: "Get help from our team",
        rightText: "",
        handleClick: (e) => {
          // props.handleOpenContactModal(e);
        },
      },
    ];
    return options;
  },
  getHomeLinksByTenantType(tenantType) {
    if (tenantType == "vendor") {
      return this.vendorHomeLinks();
    }
    if (tenantType == "consumer") {
      return this.consumerHomeLinks();
    }
    if (tenantType == "admin") {
      return this.consumerHomeLinks();
    }

    return this.consumerHomeLinks();
  },
  getPath(prf) {
    let path;
    let profile = prf;
    let isVendor = profile && profile.tenantType == "vendor" ? true : false;

    if (profile) {
      let showOnboarding = false;
      // Preferences.getPreferenceValue(
      //   profile.preferences,
      //   "show-onboarding"
      // );
      console.log("getPath -> Show onboarding", showOnboarding);
      console.log("getPath -> Get path from profile", profile);

      if (showOnboarding && isVendor) {
        console.log(
          "getPath -> Show onboarding is true and isVendor is true, path is /onboarding"
        );
        path = "/onboarding";
      } else if (showOnboarding && !isVendor) {
        console.log(
          "getPath -> Show onboarding is true, and isVendor is false, path is /onboarding"
        );
        path = "/onboarding";
      } else if (profile.tenantType && profile.tenantType == "vendor") {
        console.log("getPath -> Tenant type is vendor, path is /home");
        path = "/home";
      } else {
        console.log("getPath -> Tenant type is not vendor, path is /home");
        path = "/home";
      }
    } else {
      console.log("getPath -> No profile, going back to sign in");
      path = "/signin";
    }
    console.log("Returning path", path);
    return path;
  },
};
