import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import MaintenanceImage from "../images/maintenance.png";
import HeroImage from "../images/login-bg.webp";
import RiskLogo from "../images/risk-logo.png";
import Logo from "../images/logo.webp.png";

import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import Button from "../components/Button";
import { usePersistStore } from "../store/usestore";
import { _stripe } from "../modules/_stripe";
import { features } from "../modules/_features";
import Input from "../components/Input";
import { _navigation } from "../modules/_navigation";
import { Store } from "../layouts/store";
import useGlobalState from "../hooks/useGlobalState";
import { audit } from "../modules/_audit";
import { Preferences } from "../modules/_user_preferences";
// import { Projects } from "../modules/_projects";
// import { Solicitations } from "../modules/_solicitations";
import { useParams } from "react-router-dom";
import { System } from "../modules/_system";
import NotificationBanner from "../components/NotificationBanner";
import LoginCard from "../components/LoginCard";
import LoginBackground from "../components/LoginBackground";

function Signin() {
  const specialAnnouncement = false;
  const history = useHistory();
  const [maintenance, setMaintenance] = useState(false);
  const [announcement, setAnnouncement] = useState(false);
  const [announcementText, setAnnouncementText] = useState("");
  // const [isAuthenticated, setIsAuthenticated] = useState();
  const { isAuthenticated, isSuccess, profile, error, loginAsync, token } =
    usePersistStore();
  const [loginMessage, setLoginMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const allowedRoles = ["Manager", "Administrator", "Super Admin"];
  const { updateState } = useGlobalState();
  // useEffect(() => {
  //   features.getFeatureFlags();
  // }, []);

  useEffect(() => {
    (async () => {
      console.log("Checking for system messages");
      let systemMessages = await System.GetSystemMessages();
      if (systemMessages) {
        console.log("System Messages Found");
        //find a name in the result called "Maintenance" and if it is true, set the maintenance state to true
        let maintenance = systemMessages.find((m) => m.name === "Maintenance");
        if (maintenance) {
          console.log("Maintenance message found", maintenance);
          setMaintenance(true);
        }
        let announcement = systemMessages.find(
          (m) => m.name === "Announcement"
        );
        if (announcement) {
          console.log("Announcement message found", announcement);
          setAnnouncement(true);
          setAnnouncementText(announcement.description);
        } else {
          console.log("No announcement message found");
        }
      }
    })();
  }, []);

  const auth = async (event) => {
    setLoader(!loader);
    event.preventDefault();
    console.log("setProfile Create initial user profile", {});
    _auth.setUserProfile({});
    console.info(event);
    var u = event.target.elements.email.value;
    var p = event.target.elements.password.value;
    console.info(u, p);
    let userData = {
      email: u,
      password: p,
    };

    let response = await loginAsync(userData);

    let tenantId = null;
    let access_denied = false;
    let path = history.location.state?.from || "/onboarding";
    // if (history.location.state?.from) {
    //   let urlParts = history.location.state?.from.split("/");
    //   let projectIndex = urlParts.indexOf("project-details");
    //   let projectId = urlParts[projectIndex + 1];
    //   if (projectIndex > -1 && projectId) {
    //     ({ tenantId, access_denied } = await validateAndProcess(
    //       history,
    //       tenantId,
    //       response,
    //       access_denied
    //     ));
    //   } else {
    //     path = "/onboarding";
    //   }
    // }

    console.log("loginAsync Response", response);

    if (response) {
      // console.log("loginAsync Response", response);
      console.log("setProfile Update user profile", response);
      _auth.setUserProfile(response);
      audit.logAction("Login Successful");
      console.log("Getting feature flags");
      // features.getFeatureFlags();
      let flags = await features.featureFlags();
      if (flags) {
        updateState("flags", flags.data ? flags.data : []);
      } else {
        updateState("flags", []);
      }

      if (response.MFA == true) {
        //if the user has MFA enabled, go to MFA page, regardless how many tenants they have
        console.log("sign in redirect to mfa", response);
        path = "/mfa";
        history.push({
          state: { profile: response, from: path },
          pathname: path,
        });
      } else {
        //if the user has MFA disabled, check if they have more than one tenant
        let tenantCount = response.tenantCount;
        let role = response.companyRole;
        if (
          history.location.state?.from &&
          history.location.state?.from.includes("connections/accept")
        ) {
          //workaround for the connection accept page because
          //it doesn't require a selected tenant. It will be selected in the accept page
          tenantCount = 1;
        }
        if (tenantCount > 1) {
          //if they have more than one tenant, go to tenant selection page
          path = access_denied ? "/access-denied" : "/select-tenant";
          history.push({
            state: {
              profile: response,
              tenantId: tenantId,
              from: history.location.state?.from,
            },
            pathname: path,
          });
        } else {
          response.tenantId = response.tenants[0].tenantId._id;
          response.tenantName = response.tenants[0].tenantId.tenantTitle;
          response.tenantType = response.tenants[0].tenantId.type;

          let isRenamed = response.tenants[0].tenantId.isRenamed;

          response.tenantRole = response.tenants[0].role;

          response = _auth.buildProfile(response);

          console.log("setProfile Update user profile", response);
          _auth.setUserProfile(response);
          //if they have one tenant, get their stripe information
          // if (process.env.REACT_APP_NODE_ENV !== "local") {
          // _stripe.getStripeData(); //get stripe data and set it in the cache. this also creates websocket connection
          // }
          let path =
            history.location.state?.from || _navigation.getPath(response);
          path = access_denied ? "/access-denied" : path;
          history.push({
            state: { profile: response },
            pathname: path,
          });
          // if (!showOnboarding || showOnboarding === false) {
          //   //check if we need to show onboarding page
          //   console.log("sign in redirect to home");
          //   // path = "/home";
          //   path = _navigation.getPath(response);
          //   history.push({
          //     state: { profile: response },
          //     pathname: path,
          //   });
          // } else {
          //   //if they have one tenant, and they have not completed onboarding, go to onboarding page
          //   history.push({
          //     state: { profile: response },
          //     pathname: "/onboarding",
          //     // pathname: _navigation.getPath(response),
          //   });
          // }
        }
      }
    } else {
      console.log("loginAsync FALSE Response", response);
      //log login failed on backend
      setLoader(false);
    }
  };

  return (
    <main className="">
      {announcement && announcement === true && (
        <div className="relative w-screen top-0">
          <NotificationBanner
            showClose={false}
            text={announcementText}
            color="emerald"
          />
        </div>
      )}
      {maintenance && maintenance === true && (
        <div
          className="bg-white z-50 h-full absolute top-0 bottom-0 left-0 right-0
        p-6"
        >
          <div className="flex flex-col md:flex-row h-full justify-around content-center">
            <div className="flex flex-col h-full justify-center content-center text-center w-full md:w-1/2">
              <h1 className="text-6xl font-semibold text-slate-800 mb-6">
                We're building something better!
              </h1>
              <p className="text-slate-600">
                At Breachlink, we know that you do your best work when we do
                ours. That's why we're performing a few hours of system
                maintenance. When we're back, we'll be better than ever!{" "}
              </p>
              <p className="mt-6">
                Need help right away? Email us at{" "}
                <a
                  href="mailto:support@breachlink.com"
                  className="text-brand-600 hover:text-primary-500"
                >
                  support@breachlink.com
                </a>
              </p>
            </div>
            <div className="flex flex-col h-full justify-center content-center text-center w-full md:w-1/2">
              <img
                className="h-full w-auto"
                src={MaintenanceImage}
                alt="Auth Decoration"
              />
            </div>
          </div>
        </div>
      )}
      <LoginBackground />
      <div className="  relative grow-1 justify-center">
        {/* Content */}
        {/* <div className=" justify-center content-center"> */}
        {/*Removed divide-x below   */}
        <div className="grid grid-cols-1 lg:grid-cols-1 min-h-screen  flex grow-0 flex-col content-center justify-center">
          {/* Begin content split */}
          <div className=" flex flex-col h-screen justify-center content-center">
            {/* Left Side card*/}
            <LoginCard title="Login to Clairifi Security">
              <div className="max-w-sm mx-auto px-4 ">
                {/* <h1 className="text-3xl text-slate-800 font-bold mb-6">Sign in</h1> */}
                {/* Form */}
                <form onSubmit={auth}>
                  <div className="space-y-4">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1 "
                        htmlFor="email"
                      >
                        Email Address
                      </label>
                      <Input
                        type="email"
                        placeholder="Work email address"
                        id="email"
                      />
                      {/* <input
                      id="email"
                      className="form-input rounded-md w-full 
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-brand-500 
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-brand-500 focus:ring-1 focus:ring-brand-500"
                      type="email"
                    /> */}
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <Input
                        type="password"
                        // className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                        // label="Work Email address"
                        placeholder="Password"
                        id="password"
                        autoComplete="on"
                      />
                      {/* <input
                      id="password"
                      // className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      className=" rounded-md w-full
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-brand-500
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-brand-500 focus:ring-1 focus:ring-brand-500"
                      type="password"
                      autoComplete="on"
                    /> */}
                    </div>
                  </div>
                  <div className=" items-center justify-between mt-6">
                    {/* {loader ? (
                    <div class="flex justify-center items-center">
                      <img
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                        src={Spinner}
                        width="32"
                        height="32"
                        alt="loading.."
                      />
                    </div>
                  ) : ( */}
                    <div className="text-sm">
                      <div className="py-2">
                        By signing in, you agree to our{" "}
                        <a
                          href="https://www.breachlink.com/terms"
                          //open in new window
                          target="_blank"
                          className="font-extra-small text-brand-600 hover:text-primary-500"
                        >
                          {" "}
                          Terms and Conditions
                        </a>
                      </div>
                    </div>
                    <Button
                      enabled={!loader}
                      loader={loader}
                      type="submit"
                      // disabled
                      className="text-center items-center justify-center"
                      // rounded
                      disabled={maintenance && maintenance === true}
                      fullWidth
                      color="secondary"
                      size="md"
                      // handleClick={null}
                    >
                      Sign in
                    </Button>
                    <div className="flex items-center justify-between mr-1 mt-5">
                      <Link
                        className="text-sm text-accent-500 underline hover:text-accent-700"
                        to="/forgot-password"
                        disabled={maintenance && maintenance === true}
                      >
                        Forgot Password
                      </Link>
                      <Link
                        className="text-sm text-brand-500  hover:text-accent-700"
                        to="/signup"
                        disabled={maintenance && maintenance === true}
                      >
                        Sign Up
                      </Link>
                    </div>
                  </div>
                  {error && (
                    <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                      {error}
                    </div>
                  )}
                </form>

                {/* Footer */}
                {isAuthenticated === false && (
                  <div className="flex grow-0 items-center justify-center mt-6  text-red-500 text-sm">
                    {/* Username or password is incorrect. */}
                    {loginMessage}
                  </div>
                )}
                {/* <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{" "}
                  <Link
                    className="font-medium text-accent-500 hover:text-accent-600"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </div>
              </div> */}
              </div>
            </LoginCard>
          </div>
          {/* Right Side */}
        </div>
        {/* </div> */}
      </div>
    </main>
  );
}

export default Signin;

// async function validateAndProcess(history, tenantId, response, access_denied) {
//   // Extract project and solicitation IDs from the URL
//   let urlParts = history.location.state?.from.split("/");
//   let projectIndex = urlParts.indexOf("project-details");
//   let solicitationIndex = urlParts.indexOf("solicitation-details");
//   let projectId = urlParts[projectIndex + 1];
//   let solicitationId = urlParts[solicitationIndex + 1];

//   try {
//     // Get project details using the project ID
//     let project = await Projects.getById({
//       _id: projectId,
//     });

//     if (project) {
//       // Validate project based on the tenant ID
//       tenantId = project.tenantId;
//       let tenantIndex = response.tenants.findIndex(
//         (t) => t.tenantId._id === tenantId
//       );

//       if (tenantIndex == -1) {
//         access_denied = true;
//       }

//       // If solicitation ID exists, get solicitations and validate the solicitation
//       if (solicitationId) {
//         const solicitations = await Solicitations.getByProjectId({
//           projectId: projectId,
//           tenantId: tenantId,
//         });

//         let solicitationIndexObj = solicitations.findIndex(
//           (t) => t._id === solicitationId
//         );

//         if (solicitationIndexObj == -1) {
//           access_denied = true;
//         }
//       }
//     } else {
//       // Handle the case when the project is not found
//       console.error("Project not found.");
//       access_denied = true;
//     }
//   } catch (error) {
//     console.error("Error retrieving project:", error.message);
//     // Handle the error, provide a meaningful response, or log it for debugging
//     access_denied = true; // Set access_denied to true in case of an error
//   }

//   // Return the result, including tenantId and access_denied
//   return { tenantId, access_denied };
// }
