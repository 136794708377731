import React, { useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/20/solid";
import OnboardingImage from "../images/onboarding-image.jpg";
import OnboardingDecoration from "../images/auth-decoration.png";
import { _onboarding_options } from "../modules/_onboarding_options";
import LandingIntro from "../components/LandingIntro";
import ArrowSteps from "../components/ArrowSteps";
import StackedList from "../components/StackedList";
import { current } from "tailwindcss/colors";
import { _assessment } from "../modules/_assessment";
import { _auth } from "../modules/_auth";
import { audit } from "../modules/_audit";
import { FeatureFlag } from "../components/FeatureFlag";
const steps = _onboarding_options.getOnboardingSteps();
const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();
const options = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AssessmentSelect(props) {
  const history = useHistory();
  // const [profile, setProfile] = useState();
  const [assessment_types, setAssessmentTypes] = useState([]);
  let profile = _auth.getUserProfile();
  // setProfile(profile);

  //async useeffect
  useEffect(() => {
    (async () => {
      const assessment_types = await _assessment.getTemplates();

      setAssessmentTypes(assessment_types);
    })();
  }, []);

  useEffect(() => {
    _auth.getAuth(history);
  }, []);
  const goToStartAssessment = (a) => {
    let type = a.key;
    let name = a.name;

    let state = null;
    if (type != "pre") {
      state = {
        isPreassessment: false,
        assessment_type: type,
        assessment_name: name,
        resume: false,
        // new_history_item: _assessment.getJsonTemplate(),
      };
    } else {
      state = {
        isPreassessment: true,
        assessment_type: "pre",
        assessment_name: a.name,
        resume: false,
      };
    }

    return history.push({
      pathname: "/assessment",
      state: state,
    });
  };

  const [selectedRole, setSelectedRole] = useState(roles[0]);
  const [selectedDepartment, setSelectedDepartment] = useState(departments[0]);
  const [selectedSize, setSelectedSize] = useState(companySize[0]);
  const [selectedLocation, setSelectedLocation] = useState(companyLocation[0]);
  const [currentStep, setStep] = useState(1);
  return (
    // <StackedList title="Other actions" options={options} showButtons={false} />
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto bg-white">
      {/* Page header */}
      {/* <div className="sm:flex sm:justify-between sm:items-center mb-8"> */}
      {/* <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Choose assessment
          </h1>
        </div> */}
      {/* </div> */}

      {/* Content */}
      <div className="max-w-md mx-auto">
        <div className="min-h-screen h-full flex flex-col after:flex-1">
          <div className="m-1.5">
            {/* Modal content */}
            <div className="px-5 pt-4 pb-1">
              <div className="text-sm">
                <div className="text-center space-y-6">
                  {/* <svg
                    className="mx-auto h-12 w-12 text-slate-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg> */}
                  <h2 className="mt-2 text-lg font-medium text-slate-900">
                    Take an assessment
                  </h2>
                  <div className="mt-1 text-sm text-slate-500 mb-3">
                    Choose an assessment below to begin. You can save your
                    progress and come back as needed.
                  </div>
                </div>

                {/* Options */}
                <ul className="space-y-2 mb-4 mt-6">
                  {assessment_types &&
                    assessment_types.map((a) => {
                      if (
                        a.name == "NST" &&
                        [("demo", "62fe0874afaa5232e87df250")].includes(
                          profile.tenantId
                        ) == false
                      ) {
                        return null;
                      } else {
                        return (
                          a.status != "draft" && (
                            <FeatureFlag name={a.key} role={profile.role}>
                              <li key={a.key}>
                                <button
                                  disabled={
                                    a.status !== "published" &&
                                    a.status !== "beta"
                                  }
                                  onClick={() => goToStartAssessment(a)}
                                  // className="w-full h-full text-left py-3 px-4 rounded bg-white border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out"
                                  className="w-full h-full text-left py-3 px-4 rounded bg-white 
                              border border-gray-300
                              shadow-sm duration-150 ease-in-out
                              hover:border-gray-300 hover:shadow-md hover:bg-gray-50
                              focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500
                              
                             "
                                >
                                  <div className="flex items-center">
                                    {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                                    <div className="grow">
                                      <div className="flex flex-wrap items-center justify-between mb-0.5">
                                        <span className="font-semibold text-slate-800">
                                          {a.name}
                                          {a.status != "published" && (
                                            <span className="normal-case text-xs italic text-primary-500 align-top ml-1">
                                              {a.status}
                                            </span>
                                          )}
                                        </span>
                                        {/* <span>
                                <span className="font-medium text-green-600">
                                  $59.00
                                </span>
                                /mo
                              </span> */}
                                      </div>
                                      <div className="text-xs">
                                        {a.description}
                                      </div>
                                    </div>
                                  </div>
                                </button>
                              </li>
                            </FeatureFlag>
                          )
                        );
                      }
                    })}
                </ul>
                {/* <div className="text-xs text-slate-500">
                  After selecting your assessment, click Next to begin.
                </div> */}
              </div>
            </div>
            {/* Modal footer */}
            <div className="px-5 py-8">
              <div className="flex flex-wrap justify-end space-x-2">
                {/* <Link to="/assessments"> */}
                {/* <button
                  className="btn-sm border-gray-200 hover:border-gray-300 text-slate-600"
                  onClick={(e) => {
                    e.stopPropagation();
                    audit.logAction("Abandon Assessment Selection");
                    handleCancel();
                  }}
                >
                  Cancel
                </button> */}
                {/* </Link> */}
                {/*                 
                <button
                  className="btn-sm bg-primary-500 hover:bg-primary-600 text-white"
                  onClick={() => goToStartAssessment()}
                >
                  Next
                </button> */}
                {/* </Link> */}
              </div>
            </div>

            {/* End */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentSelect;
