import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { current } from "tailwindcss/colors";
import Button from "../../components/Button";
import { FeatureFlag } from "../../components/FeatureFlag";
import MenuTag from "../../components/MenuTag";
import { Icon } from "@iconify/react";

function SettingsSidebar(props) {
  const tabname = props.panel;
  const setPanel = props.setPanel;
  const defaultPanel = props.defaultPanel;
  const defaultGroup = props.defaultGroup;

  const settingsTabs = props.settingsTabs;
  const preferenceTabs = props.preferenceTabs;
  const location = useLocation();
  const { pathname } = location;
  const [currentTab, setCurrentTab] = useState(defaultPanel);
  const [currentGroup, setCurrentGroup] = useState(defaultGroup);
  const profile = props.profile;

  // useEffect(() => {
  //   setCurrentTab(tabname);
  // }, [tabname]);
  console.log("SettingsSidebar", settingsTabs);

  const switchTabs = (tabId, groupId, groupName) => {
    setCurrentTab(tabId);
    setCurrentGroup(groupId);
    console.info("switchTabs", tabId, currentTab);
    setPanel(tabId, groupId, groupName);
  };

  const isVisible = (tab) => {
    // console.log("isVisible", tab.name, tab.visible);
    let secure = tab.secure || false;

    let viewable = true;
    let allowed = false;
    if (secure && secure == true) {
      allowed =
        tab.view?.includes(props.profile?.role) ||
        props.profile?.sysRole == "Super Admin";
    } else {
      allowed = true;
    }
    if (tab.visible === false) {
      viewable = false;
    }

    return viewable && allowed;
  };

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 min-w-60 md:space-y-3">
      {/* Group 1 - USER PREFERENCES */}
      {/* {preferenceTabs && preferenceTabs.length > 0 && (
        <div>
          <div className="text-xs font-semibold text-slate-400 uppercase mb-3">
            My Preferences
          </div>
          <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
            {preferenceTabs.map((tab) => (
              <li className="mr-0.5 md:mr-0 md:mb-0.5">
                <NavLink
                  to={"# "}
                  className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                    currentTab == tab.id &&
                    currentGroup == "Preferences" &&
                    "bg-primary-50"
                  }`}
                  onClick={() => switchTabs(tab.id, "Preferences")}
                >
                  {" "}
                  <span
                    className={`text-sm font-medium ${
                      currentTab == tab.id && currentGroup == "Preferences"
                        ? "text-primary-500"
                        : "hover:text-slate-700"
                    }`}
                  >
                    {tab.name}
                  </span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )} */}

      {settingsTabs.map(
        (tabGroup) =>
          tabGroup.tabs && (
            <FeatureFlag
              overrideFlags={true}
              type="auth"
              role={profile.role}
              key={`${tabGroup.name} Settings`}
              featureName={`${tabGroup.name} Settings`}
            >
              <div>
                <div className="text-xs font-semibold text-slate-400 uppercase mb-3">
                  {tabGroup.name}
                </div>
                <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
                  {tabGroup.tabs.map((tab) => {
                    if (isVisible(tab)) {
                      return (
                        // <FeatureFlag
                        //   type="auth"
                        //   role={profile.tenantRole}
                        //   key={tab.id}
                        //   featureName={tab.name}
                        // >
                        <li className="mr-0.5 md:mr-0 md:mb-0.5">
                          <NavLink
                            to={"# "}
                            disabled={true}
                            className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                              currentTab == tab.id &&
                              currentGroup == tabGroup.id
                                ? "bg-brand-100 text-brand-700"
                                : "text-slate-500 hover:text-slate-700"
                            }`}
                            onClick={() => {
                              if (tab.disabled == true) {
                                return;
                              } else {
                                switchTabs(tab.id, tabGroup.id, tab.name);
                              }
                            }}
                          >
                            <span
                              className={`text-sm font-medium flex item-center ${
                                currentTab == tab.id &&
                                currentGroup == tabGroup.id
                                  ? "text-brand-700"
                                  : "text-slate-500 hover:text-slate-700"
                              }`}
                            >
                              {tab.iconify && (
                                <Icon
                                  icon={tab.iconify}
                                  className="h-4 w-4 mr-1"
                                />
                              )}{" "}
                              {tab.name}
                            </span>
                            {tab.status && (
                              <MenuTag uppercase status={tab.status} />
                            )}
                          </NavLink>
                        </li>
                        // </FeatureFlag>
                      );
                    }
                  })}
                </ul>
              </div>
            </FeatureFlag>
          )
      )}
      {/* Group 2 - ADMIN SETTINGS */}
    </div>
  );
}

export default SettingsSidebar;
