import React from "react";

import { useState } from "react";
import { _auth } from "../modules/_auth";
import { useHistory } from "react-router-dom";
// import { NavLink, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import Input from "../components/Input";
import InputList from "./InputList";

import { _user } from "../modules/_user";
import Tip from "./Tip";

export function InviteUser(props) {
  const type = props.type;
  const [open, setOpen] = useState(true);
  const [isValidForSignup, setIsValidForSignup] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeInvalid, SetAccessCodeInvalid] = useState(false);
  const [accessCodeErrorMessage, setAccessCodeErrorMessage] = useState("");
  const [signUpErrorMessage, setSignUpErrorMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageName, setErrorMessageName] = useState("");
  const [errorMessageRole, setErrorMessageRole] = useState("");
  const [errorMessageTenantType, setErrorMessageTenantType] = useState("");
  const [role, setRole] = useState("");
  const [errorMessageCompanyName, setErrorMessageCompanyName] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tenantType, setTenantType] = useState("consumer");
  const currentUserTenant = _auth.getUserProfile();
  console.info("CURRENT USER TENANT", currentUserTenant);
  const profile = props.profile;

  const onChangeText = (value, variant) => {
    console.info("COLLAB onChangeText", variant, value);
    setSignUpErrorMessage("");
    //trim the value before setting it

    // this was causing and issue if you need this for specific use case add it inside condition
    // value = value.trim();

    if (variant === "email") {
      setEmail(value);
      setErrorMessageEmail("");
    } else if (variant === "username") {
      setFullName(value);
      setErrorMessageName("");
    } else if (variant === "password") {
      setPassword(value);
      setErrorMessagePassword("");
    } else if (variant === "confirmpassword") {
      setConfirmPassword(value);
      setErrorMessagePassword("");
    } else if (variant === "companyName") {
      setCompanyName(value);
      setErrorMessageCompanyName("");
    } else if (variant === "tenantType") {
      setTenantType(value);
      setErrorMessageTenantType("");
    } else if (variant === "accessCode") {
      setAccessCode(value);
      setAccessCodeErrorMessage(false);
    } else if (variant === "role") {
      setRole(value.value);
      setErrorMessageRole("");
      //   setSignUpErrorMessage(value.name);
    }
  };
  const onChangeDropDown = (value, variant) => {
    console.info("COLLAB onChangeDropDown", variant, value.value);
    if (variant === "role") {
      setRole(value.value);
      setErrorMessageRole("");
      //   setSignUpErrorMessage(value.name);
    }
  };

  const sgn = async () => {
    // let resp = await _auth.addUser();
    (async () => {
      // console.info("Is it valid for signup?", isValidForSignup);

      console.info("CURRENT USER TENANT", currentUserTenant);

      let companyRole = null;
      let sysRole = null;
      if (role === "Super Admin") {
        sysRole = "Super Admin";
        companyRole = "Administrator";
        setRole("Manager");
      } else if (role === "Administrator") {
        sysRole = null;
        companyRole = "Administrator";
        setRole("Manager");
      } else if (role === "Manager") {
        sysRole = null;
        companyRole = "User";
        setRole("Manager");
      } else if (role === "Collaborator") {
        sysRole = null;
        companyRole = "User";
        setRole("Collaborator");
      } else if (role === "Observer") {
        sysRole = null;
        companyRole = "Observer";
        setRole("Observer");
      } else {
        sysRole = null;
        companyRole = "User";
        setRole("Manager");
      }

      let data = {
        name: fullName ? fullName : profile.name,
        email: email ? email : profile.email,
        domains: email ? email.split("@")[1] : profile.email.split("@")[1],
        role: role,
        sysRole: sysRole,
        companyRole: companyRole,
        tenantId: currentUserTenant.tenantId,
        admin: currentUserTenant.name,
        tenantName: currentUserTenant.tenantName,
        company:
          props.inviteType == "add-users" ||
          props.inviteType == "create-new-tenant"
            ? currentUserTenant.tenantCompany
            : companyName,
        companyName:
          props.inviteType == "add-users" ||
          props.inviteType == "create-new-tenant"
            ? currentUserTenant.tenantCompany
            : companyName,
        companyEmail:
          props.inviteType == "add-users" ||
          props.inviteType == "create-new-tenant"
            ? null
            : email,
        companyId: currentUserTenant.tenantCompanyId,
        createTenant: props.type == "add" ? true : false,
        createCompany:
          props.inviteType == "add-users"
            ? false
            : props.inviteType == "create-new-tenant"
            ? false
            : true,
        type: props.type,
        tenantType:
          props.inviteType == "add-users" ? currentUserTenant.type : tenantType,
        from:
          props.inviteType == "add-users"
            ? "addUserToTenant"
            : props.inviteType == "create-new-tenant"
            ? "createNewTenant"
            : "createNewCompanyAndTenant",
      };
      console.info("COLLAB Single User", data);
      const response = await _user.addSingleUser(data);
      // const response = await _auth.signup2(data);
      console.info("COLLAB response", response);
      if (response && response.data) {
        setSuccess(true);
        setLoading(false);
        setSignUpErrorMessage("Add users successful");
        // return response;
      } else {
        console.info("Adding Failed", response);
        setSuccess(false);
        setLoading(false);
        setSignUpErrorMessage(response.response.data.errorMessage);
        // return response;
      }

      //   return response;
    })();
  };

  const inviteAgain = () => {
    setSuccess(false);
    setEmail("");
    setPassword("");
    setFullName("");
    setCompanyName("");
    setAccessCode("");
    setRole("");
    setErrorMessageCompanyName("");
    setErrorMessageEmail("");
    setErrorMessageName("");
    setErrorMessagePassword("");
    setErrorMessageRole("");
    setAccessCodeErrorMessage("");
    setSignUpErrorMessage("");
  };

  const onRegister = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let amIvalid = true;
    setLoading(true);

    setIsValidForSignup(true);
    const isValid = new Promise((resolve, reject) => {
      console.info("onRegister Clicked");
      let emailRegex = new RegExp(/\S+@\S+\.\S+/);

      if (!fullName) {
        if (props.inviteType !== "create-new-tenant") {
          console.log("please enter full name");
          setErrorMessageName("Please enter your full name");
          amIvalid = false;
          setIsValidForSignup(false);
        }
      }
      if (!email || !emailRegex.test(email)) {
        if (props.inviteType !== "create-new-tenant") {
          console.log("please enter email");
          setErrorMessageEmail("Please enter a valid email address");
          amIvalid = false;
          setIsValidForSignup(false);
        }
      }
      if (!email || !emailRegex.test(email)) {
        if (props.inviteType !== "create-new-tenant") {
          console.log("Please use a company email address");
          setErrorMessageEmail("Please use a company email address");
          amIvalid = false;
          setIsValidForSignup(false);
        }
      }
      if (!companyName) {
        if (props.inviteType == "create-new-company") {
          console.log("please enter company name");
          setErrorMessageCompanyName("Please enter your company name");
          amIvalid = false;
          setIsValidForSignup(false);
        }
      }
      if (!role) {
        if (props.inviteType !== "create-new-tenant") {
          console.log("please select a role");
          setErrorMessageCompanyName("Please select a role");
          amIvalid = false;
          setIsValidForSignup(false);
        }
      }
      // if (!password) {
      //   console.log("please enter password");
      //   setErrorMessagePassword("Please enter a password");
      //   setIsValidForSignup(false);
      // }

      // if (password.toString() !== confirmPassword.toString()) {
      //   console.log("passwords do not match");
      //   setErrorMessagePassword("The passwords you entered do not match");
      //   setIsValidForSignup(false);
      // }

      // I have done this because you are using promises and I want to use async await - and this is know as IEEE function

      resolve("Success!");
    })
      .then(() => {
        const isNaughtyList = new Promise((resolve, reject) => {
          let isNaughty = _auth.checkNaughtyList(email);
          resolve(isNaughty);
        })
          .then((naughty) => {
            console.log("isNaughtyListResp", naughty);
            if (naughty === true) {
              amIvalid = false;
              setErrorMessageEmail("Personal emails are not allowed");
              console.info(
                "Found in naughty list, setting IsValidForSignup to false"
              );
              setIsValidForSignup(false);
            } else {
              console.info(
                "Not found in naughty list, setting IsValidForSignup to true"
              );
              setIsValidForSignup(true);
            }
          })
          .then(() => {
            console.info("AM I VALID?", amIvalid);
            console.info("NOW CHECKING IF WE CAN CREATE THE ACCOUNT");
            if (amIvalid === false) {
              console.info(
                "Cannot start creation, not valid",
                isValidForSignup
              );
              setLoading(false);
            } else {
              console.info("Are we valid for signup?", isValidForSignup);
              setLoading(true);
              console.info(
                "Everything passed, signing up",
                email,
                fullName,
                role
              );
              sgn();
              // .then((resp) => {
              //   console.log("My response", resp);

              //   if (resp) {
              //     console.log("Signup success", resp);
              //     setLoading(false);
              //     setSuccess(true);
              //     // history.push("/signupconfirmation");
              //   } else {
              //     console.log("You failed", resp);
              //     setLoading(false);
              //     setSignUpErrorMessage(resp.errorMessage);
              //     setSuccess(true);
              //   }
              // })
              // .catch((error) => {
              //   setLoading(false);
              //   setSuccess(false);
              //   console.info("THISERROR", error.errorMessage);
              //   setSignUpErrorMessage(error.errorMessage);
              // });
            }
          })
          .catch((error) => {
            console.info("error", error);
          });
      })
      .catch((error) => {
        console.info("error", error);
      });
  };
  return (
    <div id="invite-user-container" className="max-w-md   pt-0 pb-24 h-full ">
      <div className="flex justify-between items-center mb-10">
        <div className="flex items-center">
          {
            //check whether I should be using 'add' or something else
            props.inviteType == "create-new-company" ? (
              <div>
                <h1 className="text-xl text-slate-800 font-bold">
                  Create a new customer
                </h1>
                <p className="text-base text-slate-900 mt-4">
                  Use this form invite a new customer create an account
                </p>
              </div>
            ) : props.inviteType == "create-new-tenant" ? (
              <div>
                <h1 className="text-xl text-slate-800 font-bold">
                  Create a new workspace
                </h1>
                <p className="text-base text-slate-900 mt-4 mb-2">
                  Create a new workspace for users in your company to
                  collaborate in.
                </p>
                <Tip noMargin>
                  You will be the first user of your workspace. You can add more
                  users to your workspace after you create it by clicking the
                  Done but below and then switching workspaces from the
                  navigation bar.
                </Tip>
              </div>
            ) : (
              <div>
                <h1 className="text-xl text-slate-800 font-bold">
                  Add a collaborator
                </h1>
                <p className="text-base text-slate-900 mt-4">
                  Use this form to invite a new or existing user to join your
                  workspace to collaborate with you.
                </p>
              </div>
            )
          }
        </div>
      </div>
      {/* <NotificationBanner
        className={"sticky top-0"}
        setOpen={setOpen}
        text={"Not working yet"}
        showClose={false}
      /> */}
      {/* <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
        {success ? "Invitation sent!" : "Invite a user"}
      </h2> */}
      {!success ? (
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <form className="space-y-6" method="post">
            {props.inviteType !== "create-new-tenant" && (
              <Input
                type="text"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  sm:text-sm "
                label="Full Name"
                placeholder="Full name"
                id="input"
                value={fullName}
                onChange={(e) => onChangeText(e.target.value, "username")}
                errorText={errorMessageName ? errorMessageName : undefined}
              />
            )}
            {props.inviteType == "create-new-company" && (
              <Input
                type="text"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  sm:text-sm "
                label="Company Name"
                placeholder="Company Name"
                id="input"
                value={companyName}
                onChange={(e) => onChangeText(e.target.value, "companyName")}
                errorText={errorMessageName ? errorMessageName : undefined}
              />
            )}
            {props.inviteType !== "create-new-tenant" && (
              <Input
                type="text"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  sm:text-sm"
                label="Work Email address"
                placeholder="Work email address"
                id="input"
                value={email}
                onChange={(e) => onChangeText(e.target.value, "email")}
                errorText={errorMessageEmail ? errorMessageEmail : undefined}
              />
            )}
            {props.inviteType !== "create-new-tenant" && (
              <InputList
                profile={profile}
                // setSelectedRole={(e) => onChangeText(e.target.value, "role")}
                onChangeDropDown={(e) => onChangeDropDown(e, "role")}
              />
            )}
            {(props.inviteType == "create-new-company" ||
              props.inviteType == "create-new-tenant") && (
              <ul class="space-y-3">
                <li className="min-h-full cursor-pointer">
                  <input
                    type="radio"
                    id="account-type-consumer"
                    name="account-type"
                    value="consumer"
                    disabled={false}
                    checked={tenantType === "consumer"}
                    onClick={() => {
                      setTenantType("consumer");
                      // console.log("onclick called", "tenantType", "consumer");
                    }}
                    class="hidden peer"
                    required
                  />
                  <label
                    for="account-type-consumer"
                    class={`
                    rounded inline-flex min-h-full items-center justify-between w-full 
                    py-3 px-4 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  
                    peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-50`}
                  >
                    <div className="flex items-center">
                      {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                      <div className="grow">
                        <div className="flex flex-wrap items-center justify-between mb-0.5">
                          <span className="flex font-semibold text-slate-800">
                            Consumer
                          </span>
                          <span>
                            {/* <span className="font-medium text-accent-600">
                                  Right Text
                                </span> */}
                          </span>
                        </div>
                        <div className="text-xs text-slate-500">
                          You're here to send bid requests, compare quotes, and
                          connect with vendors.
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </label>
                </li>

                <li className="min-h-full cursor-pointer">
                  <input
                    type="radio"
                    id="account-type-vendor"
                    name="account-type-vendor"
                    value="vendor"
                    checked={tenantType === "vendor"}
                    onClick={(e) => {
                      // e.preventDefault();
                      setTenantType("vendor");
                      // console.log("onclick called", "tenantType", "consumer");
                    }}
                    class="hidden peer"
                    required
                  />
                  <label
                    for="account-type-vendor"
                    class="inline-flex min-h-full items-center justify-between w-full py-3 px-4  text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)]  peer-checked:border-brand-600 peer-checked:text-brand-600 hover:text-gray-600 hover:bg-gray-50 "
                  >
                    <div
                    // className={`w-full h-full text-left py-3 px-4 rounded bg-white
                    //       border border-gray-300
                    //       shadow-sm duration-150 ease-in-out

                    //       focus:outline-none focus-border-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500

                    //      hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-brand-500 hover:border-gray-300 hover:border-brand-500
                    //     cursor-pointer  peer-checked:border-brand-600 peer-checked:text-brand-600

                    //       `}
                    >
                      <div className="flex items-center">
                        {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                        <div className="grow">
                          <div className="flex flex-wrap items-center justify-between mb-0.5">
                            <span className="flex font-semibold text-slate-800">
                              Vendor
                            </span>
                            <span>
                              {/* <span className="font-medium text-accent-600">
                                  Right Text
                                </span> */}
                            </span>
                          </div>
                          <div className="text-xs text-slate-500">
                            You're here to send quotes, connect with customers,
                            and win more business.
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </li>
              </ul>
            )}
          </form>

          <div className="flex items-center justify-between mt-5">
            {signUpErrorMessage !== "" && (
              <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                {signUpErrorMessage}
              </div>
            )}
          </div>
          <div className="mt-5">
            <Button
              color="secondary"
              fullWidth={true}
              disabled={
                props.inviteType !== "create-new-tenant" &&
                (fullName.length == 0 || email.length == 0)
                  ? true
                  : false
              }
              loader={loading}
              type="submit"
              className="btn btn-primary"
              onClick={onRegister}
              handleClick={onRegister}
              audit={true}
              action="Invite User"
              details={{
                user: fullName,
                email: email,
                role: role,
                inviteType: props.inviteType,
              }}
            >
              {props.inviteType == "create-new-tenant"
                ? "Create Tenant"
                : "Send Invitation"}
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-sm mb-10 ">
          {""}
          <div className="mt-5">
            <Button
              // enabled={!loader}
              loader={loading}
              type="submit"
              className="btn btn-primary"
              onClick={inviteAgain}
              handleClick={inviteAgain}
            >
              Invite another person
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
