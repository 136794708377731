import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import ToastNotification from "./components/ToastNotification";

import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";
import { GlobalStateStore } from "./store/GlobalState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <GlobalStateStore>
      <Routes />
    </GlobalStateStore>
  </Router>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
