import React from "react";
import Logo from "../images/logo.webp.png";
import HeroImage from "../images/login-bg.webp";
import { Fade } from "react-reveal";

const LoginCard = ({ title, children }) => {
  return (
    <Fade duration={1000}>
      <div className="rounded-xl bg-white z-50 max-w-md mx-auto my-auto px-6 py-8">
        <div className="flex max-w-sm mx-auto py-4 ">
          <span className="flex items-center text-3xl font-normal text-slate-700">
            <img width="40" className="mx-auto mr-4" src={Logo} />
            {title}
          </span>
        </div>
        {children}
      </div>
    </Fade>
  );
};

export default LoginCard;
