import setAuthToken from "../config/setAuthToken";
import { usePersistStore } from "../store/usestore";
import { getRequest, postRequest } from "./ApiRequest";
import { qList } from "./_assessment_questions";
import { _auth } from "./_auth";

export const _assessment = {
  async deleteAssessment(assessmentId) {
    let body = {
      assessmentId: assessmentId,
    };

    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("assessments/delete", body);
    // debugger;

    if (response) {
      return response.data;
    } else {
      return [];
    }
  },

  getAssessmentNames() {
    return [
      {
        id: 4,
        name: "Sample",
        description: "Short assessment for demo purposes",
        key: "sample",
        status: "disabled",
        intro: "this is a sample assessment",
        blurbs: {
          High: "  Congratulations on finishing the CyberSecurity Risk Assessment. It’s a great first step in the journey to becoming as Cyber Secure as possible. We strongly urge you to focus on fixing all the areas highlighted in your organization's customized Action Plan because every vulnerability is important and leaves your company exposed. If you need help, DSU makes it quick and easy to find the right vendors without the hassle. We've partnered with the industry's most reputable solution providers to provide discounted solutions for you. You're one click away from connecting with vendors who are committed to making your life easier while working to safeguard your organization.",

          Normal:
            "Congratulations on your Perfect Score. After you take a well deserved victory lap, use DSU to assess other areas of your organization's cyber readiness, train the rest of your organization, and assess and train third party vendors and contractors so that they can help secure your organization.  Cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations, but you can count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
          Low: "Sample L",
        },
      },
      {
        id: 1,
        name: "Pre-Assessment",
        description: "Start here to judge your overall readiness.",
        key: "pre",
        status: "production",
        intro:
          "The pre-assessment will let you know not only your company's current level of cyber preparedness and compliance with data privacy laws at a high level, but also give you a feeling for who may be best suited to take the more in-depth assessments.  Often, it takes the help of others to properly answer the questions for your company.  Let's get started!",
        blurbs: {
          High: "Congratulations on finishing the CyberSecurity Risk Assessment. It’s a great first step in the journey to becoming as Cyber Secure as possible.We strongly urge you to focus on fixing all the areas highlighted in your organization's customized Action Plan because every vulnerability is important and leaves your company exposed. DSU is prepared to make it quick and easy to fix the vulnerabilities identified in your Action Plan without the hassle of too many vendors. We've contracted with the industry's most reputable solution providers to provide discounted solutions for you. You're one click away from receiving integrated solutions and tools from select vendors who are committed to making your life easier while working to safeguard your organization.",

          Normal:
            "Congratulations on your Perfect Score. After you take a well deserved victory lap, allow DSU to assess cyber readiness and train the rest of your organization and third party vendors/contractors so that they can likewise secure your organization.  As cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations - Count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
          Low: "",
        },
      },
      {
        id: 2,
        name: "CSF",
        description: "Cybersecurity Framework",
        key: "csf",
        status: "production",
        intro:
          "Ready to learn how prepared you are against a cyber incident? There are existing standards, guidelines and practices in place to help you better manage and reduce your cyber risk. Once you have finished the assessment, you'll get your custom action plan highlighting any areas that need attention.  Let's get started!",
        blurbs: {
          High: "Congratulations on finishing the CyberSecurity Risk Assessment. It’s a great first step in the journey to becoming as Cyber Secure as possible. We strongly urge you to focus on fixing all the areas highlighted in your customized Action Plan below because every vulnerability is important and leaves your company exposed.",

          Normal:
            "Congratulations on your Perfect Score. After you take a well deserved victory lap, allow DSU to assess cyber readiness and train the rest of your organization and third party vendors/contractors so that they can likewise secure your organization.  As cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations - Count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
          Low: "",
        },
      },
      {
        id: 3,
        name: "NIST",
        description: "Cybersecurity Framework",
        key: "nist",
        status: "disabled",
        intro:
          "Ready to learn how prepared you are against a cyber incident? There are existing standards, guidelines and practices in place to help you better manage and reduce your cyber risk. Once you have finished the assessment, you'll get your custom action plan highlighting any areas that need attention.  Let's get started!",
        blurbs: {
          High: "Congratulations on finishing the CyberSecurity Risk Assessment. It’s a great first step in the journey to becoming as Cyber Secure as possible. We strongly urge you to focus on fixing all the areas highlighted in your customized Action Plan below because every vulnerability is important and leaves your company exposed.",

          Normal:
            "Congratulations on your Perfect Score. After you take a well deserved victory lap, allow DSU to assess cyber readiness and train the rest of your organization and third party vendors/contractors so that they can likewise secure your organization.  As cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations - Count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
          Low: "",
        },
      },
      {
        id: 4,
        name: "PCI",
        description: "Payment Card Industry Data Security Standard",
        key: "pci",
        status: "production",
        intro:
          "If your company handles credit card data, then you are in the right place.  Whether it is you, or your supplier, you want to ensure that you are up to date with the latest compliance standards.  Once you have finished the assessment, you'll get your custom action plan highlighting any areas that need attention.  Let's get started!",
        blurbs: {
          High: "Congratulations on finishing the PCI-DSS Risk Assessment. It’s a great first step in the journey to becoming as PCI compliant as possible. We strongly urge you to take immediate action and focus on fixing all the areas highlighted in your customized Action Plan below to increase your awareness of PCI-DSS requirements and safeguard your network from infiltration by hackers and other criminals. Your customers’ credit card data may be at risk.",

          Normal:
            "Congratulations on your Perfect Score. After you take a well deserved victory lap, use DSU to assess other areas of your cyber readiness, train the rest of your organization and third party vendors/contractors so that they can likewise secure your organization.  As cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations - Count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
          Low: "",
        },
      },
      {
        id: 4,
        name: "RSW",
        description: "Employee Ransomware Assessment",
        key: "rsw",
        status: "production",
        intro:
          "Employee rasonsomware awareness is a critical step in the fight against ransomware.  Let's get started!",
        blurbs: {
          High: "Congratulations on finishing the Ransomwawre Assessment. It’s a great first step in the journey to becoming as Cyber Secure as possible. We strongly urge you to focus on fixing all the areas highlighted in your customized Action Plan below because every vulnerability is important and leaves your company exposed.",
          Normal:
            "Congratulations on your Perfect Score. After you take a well deserved victory lap, allow DSU to assess cyber readiness and train the rest of your organization and third party vendors/contractors so that they can likewise secure your organization.  As cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations - Count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
          Low: "",
        },
        // blurbs: [
        //   {
        //     High: "Congratulations on finishing your Ransomware Assessment. It's a great first step, but it looks like you have some work today. Please review the questions you answered incorrectly to understand more about ransomware and the associated risks.",
        //   },
        //   {
        //     Normal:
        //       "Congratulations on finishing your Ransomware Assessment.  You are well on your way to being a ransomware expert.  Please review the questions you answered incorrectly to understand more about ransomware and the associated risks.",
        //   },
        //   { Low: "" },
        // ],
      },

      {
        id: 5,
        name: "GDPR",
        description: "General Data Protection Regulation",
        key: "gdpr",
        status: "Coming soon",
        blurbs: {
          High: "Congratulations on finishing the CyberSecurity Risk Assessment. It’s a great first step in the journey to becoming as Cyber Secure as possible.We strongly urge you to focus on fixing all the areas highlighted in your organization's customized Action Plan because every vulnerability is important and leaves your company exposed. DSU is prepared to make it quick and easy to fix the vulnerabilities identified in your Action Plan without the hassle of too many vendors. We've contracted with the industry's most reputable solution providers to provide discounted solutions for you. You're one click away from receiving integrated solutions and tools from select vendors who are committed to making your life easier while working to safeguard your organization.",

          Normal:
            "Congratulations on your Perfect Score. After you take a well deserved victory lap, allow DSU to assess cyber readiness and train the rest of your organization and third party vendors/contractors so that they can likewise secure your organization.  As cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations - Count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
          Low: "",
        },
      },
      {
        id: 6,
        name: "DBR",
        description: "Data Breach Readiness Assessment",
        key: "breach",
        status: "disabled",
        blurbs: {
          High: "Congratulations on finishing the CyberSecurity Risk Assessment. It’s a great first step in the journey to becoming as Cyber Secure as possible.We strongly urge you to focus on fixing all the areas highlighted in your organization's customized Action Plan because every vulnerability is important and leaves your company exposed. DSU is prepared to make it quick and easy to fix the vulnerabilities identified in your Action Plan without the hassle of too many vendors. We've contracted with the industry's most reputable solution providers to provide discounted solutions for you. You're one click away from receiving integrated solutions and tools from select vendors who are committed to making your life easier while working to safeguard your organization.",

          Normal:
            "Congratulations on your Perfect Score. After you take a well deserved victory lap, allow DSU to assess cyber readiness and train the rest of your organization and third party vendors/contractors so that they can likewise secure your organization.  As cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations - Count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
          Low: "",
        },
      },
      {
        id: 4,
        name: "NST",
        description: "INSUREtrust Demo",
        key: "insuretrust",
        status: "production",
        intro: "This is an INSUREtrust demo assessment.",
        blurbs: {
          High: "Congratulations on finishing the INSUREtrust Demo Assessment. It’s a great first step in the journey to becoming as Cyber Secure as possible. We strongly urge you to focus on fixing all the areas highlighted in your customized Action Plan below because every vulnerability is important and leaves your company exposed.",
          Normal:
            "Congratulations on your Perfect Score. After you take a well deserved victory lap, allow DSU to assess cyber readiness and train the rest of your organization and third party vendors/contractors so that they can likewise secure your organization.  As cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations - Count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
          Low: "",
        },

        // blurbs: [
        //   {
        //     High: "Congratulations on finishing the PCI-DSS Risk Assessment. It’s a great first step in the journey to becoming as PCI compliant as possible. We strongly urge you to take immediate action and focus on fixing all the areas highlighted in your customized Action Plan below to increase your awareness of PCI-DSS requirements and safeguard your network from infiltration by hackers and other criminals. Your customers’ credit card data may be at risk.",
        //   },
        //   {
        //     Normal:
        //       "Congratulations on your Perfect Score. After you take a well deserved victory lap, use DSU to assess other areas of your cyber readiness, train the rest of your organization and third party vendors/contractors so that they can likewise secure your organization.  As cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations - Count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
        //   },
        //   { Low: "" },
        // ],
      },
    ];
  },
  getAssessmentTypeDetails(type) {
    return this.getAssessmentNames().filter((item) => item.key === type);
  },
  getBlurb(risk, blurbs) {
    return blurbs.find((item) => item[risk]);
  },
  getBlurb_working(risk, blurbs) {
    return blurbs.find((item) => item[risk])[risk];
  },
  getPreAssessmentSteps() {
    const steps = [
      {
        id: "01",
        name: "Intro",
      },
      {
        id: "02",
        name: "Pre-assessment",
      },
      {
        id: "03",
        name: "Result",
      },
    ];
    return steps;
  },
  getAssessmentSteps() {
    const steps = [
      {
        id: "01",
        name: "Welcome",
      },

      {
        id: "05",
        name: "Assessment",
      },
      {
        id: "05",
        name: "Score",
      },
      // {
      //   id: "07",
      //   name: "Action Plan",
      // },
    ];
    return steps;
  },
  getPostAssessmentSteps() {},
  getPreAssessmentQuestions() {
    const questions = [
      {
        id: "01",
        name: "Welcome",
      },

      {
        id: "05",
        name: "Assessment",
      },
      {
        id: "05",
        name: "Score",
      },
      {
        id: "07",
        name: "Action Plan",
      },
    ];
    return questions;
  },
  getAssessmentQuestions(type) {
    if (type === "CSF") {
      const questions = [
        {
          ID: "1",
          Question:
            "Have you completed a comprehensive audit and conducted an inventory of all of your company's sensitive data so that you understand where and how the data is received, stored, managed and transmitted?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "",
          explanation:
            "You indicated that you have not taken the critical first step of conducting an internal Data and Systems Audit which would enable your organization to assess the best means to protect your company's sensitive data and minimize the risk of a security breach.  This is not a step that an organization can afford to skip as it lays the foundation to be able to apply best practices for data security. DSU Consultant on call prepared to give you some guidance and get you started on performing your company's Data and Systems Audit.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          your_answer: "No", //this is TEMPORARY.
          Notes: "",
        },
        {
          ID: "2",
          Question:
            "Does your organization i) inventory and track all approved Software and Hardware devices on your Network so that only authorized Software and Hardware are loaded, and ii) detect and remove unauthorized Software and Hardware.",
          "Keep / Retire": "",
          best_answer: "",
          best_answer: "",
          explanation:
            "You indicated that in conducting your self-audit and inventory process that you discovered certain fundamental information governance procedures that are not yet in place. Now that you have a better handle on the type of basic deficiencies in how your company is receiving, storing, managing and transmitting sensitive business data, it would be advisable to call upon a DSU Consultant to help you seek a qualified IT/Data Protection specialist to fortify and better secure your data.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "3",
          Question:
            "Do you restrict and control access to your company's critical data to only those specially authorized employees who have demonstrated a need for access and who have been vetted by background checks?",
          "Keep / Retire": "",
          best_answer: "",
          best_answer: "",
          explanation:
            "You indicated that you may not be restricting access to your company's critical data to only those personnel who have demonstrated a need for access to your company's critical data and who have been vetted by background checks. Data security standards require organizations to have strong access control measures in place. These include the following: i) Company policies and facility controls need to ensure that only authorized persons can access areas where hardcopy or digital data are available. ii) All employees (as well as contractors and vendors) need to be thoroughly vetted prior to being granted specific access to sensitive data. Background checks are recommended when they may be legally used per your attorney's direction. Restricted Access Control policies and background screening are easy enough to implement with the guidance of a DSU Consultant.  DSU also can put you in touch with an attorney who can quickly advise if there may be any local privacy laws that can limit your ability to use a background check.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "4",
          Question:
            "Do you sufficiently cover data security policies and procedures and reference in your employee handbook the applicable data protection laws or requirements such as PCI-DSS, GDPR or HIPAA?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or We don't have a handbook.",
          explanation:
            "You indicated that you may not be covering either Cyber Security best practices or PCI-DSS compliance in your employee handbook. Both the NIST Cyber Security Framework and PCI-DSS rules require organizations to implement strong 'access control measures' to ensure that only authorized persons can access sensitive company data. Such policies should be adopted and incorporated into an employee handbook that is provided to all employees. Call on your DSU Consultant to ensure that you receive a set of Cyber Security policies for your Employee Handbook in addition to templates for Incident Response Plans and Data Recovery Programs. \n",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "5",
          Question:
            "Does your company provide security awareness training to employees which includes how to avoid being victims to 'phishing' as well as ransomware?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or What's a phishing email look like?",
          explanation:
            "You indicated that your company may not have performed adequate training to prevent employees from responding to phishing emails that trick employees into opening links and attachments which contain malware. Basic employee training is critical to avoid employees from inadvertently exposing the company to hackers by improperly using company computers or being deceived by hackers deploying social engineering tricks such as 'Phishing'.\nCyber Call upon your DSU Consultant to provide you with a discounted Employee \"Phishing\" simulation program as part of your overall Cyber Security Employee Training Learning Management System. ",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "6",
          Question:
            "Are employees permitted to 'BYOD' (Bring Your Own Devices - laptops, tablets, and smart phones) to the workplace and use these devices to access privileged company information and applications?",
          "Keep / Retire": "",
          best_answer: "No",
          best_answer: "Yes or IDK",
          explanation:
            "As you indicated that your organization may not have BYOD security policies, your organization needs to implement critical data security vulnerabilities associated with employees tapping into sensitive business data while balancing legal regulations and the employee's privacy.   Be sure to seek the help of legal counsel to address privacy issues and liabilities that may emerge when businesses wipe devices after employees leave the organization. It can get tricky when an employee feels that they have ownership of the phone number and thereafter leaves your company to join a competitor.  Allow our DSU Consultant to provide for you the right BYOD employee and consultant policies along with the correct notification protocols to report missing or stolen portable computers. DSU also has a Learning Management System to properly train your employees regarding security and the proper usage of BYOD equipment. DSU consultants can also fill you in an give you ready access to helpful tech tools that happen to have complicated sounding names such as mobile device management (MDM), containerization and app virtualization.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "7",
          Question:
            "Are all system and application accounts disabled for employees or contractors that are no longer in need of them or who have left the organization?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation: "No action item",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "8",
          Question:
            "Do you use third party Call Centers or give Suppliers personal access to your critical systems?",
          "Keep / Retire": "",
          best_answer: "No",
          best_answer: "Yes or IDK",
          explanation: "",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "9",
          Question:
            "(*If Yes) Do you monitor that they are adhering to data security best practices and standards such as PCI-DSS which is contractually imposed by your merchant acquirer or card processor?]",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that your company may be utilizing either third party call centers or suppliers that have access to your IT systems or company data, but questions remain as to whether these third parties are fulfilling both proper Cyber Security practices as well their PCI-DSS responsibilities. Many security breaches have been the result of call centers or supplier vendors that have inadvertently provided hackers an entry point into their customer's sensitive data systems. You should only do business with third parties that are contractually obligated to demonstrate that they have compliant systems and security practices which can easily be monitored. Call on a DSU Consultant to better ensure that you have the right screening tools and contracts in place with your vendors and contractors.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "10",
          Question:
            "Are 'stationary' computer workstations within your organization such as at a receptionist's desk or on a showroom floor particularly accessible to outsiders? This question also encompasses workstations at unoccupied desks or in offices left unattended during short intervals such as lunch breaks or at longer spans by vacationing or former employees?",
          "Keep / Retire": "",
          best_answer: "No",
          best_answer: "Yes or IDK",
          explanation:
            "You indicated that you may not be properly securing computer sites within your organization. It is a good practice to use computers that have locks to prevent opening its case without a key. It should also be easy enough to simply disconnect or remove computers and disable USB drives that are not being used in empty offices. Even offices that are left empty at lunch or due to an employee's absence should at least be locked to keep intruders out. As it may be unrealistic to remove computers from open areas; your DSU Consultant can provide you with employee security policies and training in addition to  smart card or biometric readers to prevent unauthorized persons from logging onto the computer.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "11",
          Question:
            'Does each employee have unique and complex passwords which have been changed from the "default" password, and are passwords for critical applications changed at least every 90 days?',
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            'You indicated that there may be a problem with either changing your POS system\'s default passwords or not giving employees unique passwords to log into your network wherein these passwords need to be routinely changed at least every 90 days. Strong Cyber Security practices and PCI-DSS rules require merchants to implement strong access control measures so that only authorized persons can access cardholder data. This includes not using "default" passwords while assigning a unique and complex ID that is routinely changed to each employee with computer access.\nHave your DSU Consultant ensure that you have the necessary corporate policies and employee training  in place to ensure that your organization  does not use default passwords on any of your network management devices or remote access applications.  Seeing how easy it is to forget passwords, your DSU Consultant can also offer popular discounted Password Manager tools.',
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "12",
          Question:
            "Does your organization have an Access Control (AC) policy requiring that its servers and the server room are properly secured and locked down?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that your organization may not be doing enough to secure and lock down its servers and server rooms. Organizations need to  keep a log book in order to track personnel within the server room in addition to anywhere else where there may be sensitive data. It's a good investment to have locking devices, and authentication systems so that entry doors to server rooms can only be unlocked via a smart card, token, or biometric scan while a record is made of the ID for each person who enters. Surveillance systems are effective and state of the art systems can even be set up to send email or cell phone notification if motion is detected after business hours.  Have your DSU Consultant work with you to put together a turnkey Access Control plan and policies at affordable prices.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "13",
          Question:
            "Has your organization prepared a comprehensive Incident Response Plan which addresses what to do with your information and systems in case of a breach as well as the roles and responsibilities for those within and outside your organization who will carry out the plan? The plan should include what activities constitute an information security incident and who will be contacted.",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that you may not have prepared an Incident Response Plan in the event of a breach. Although it is hoped that your organization will never experience a security breach, the fact is that companies that do not prepare for such an event most often find themselves ill equipped to adequately manage the devastating aftermath of a security breach. Without a blueprint that provides a coordinated process for how designated company representatives manage customer notifications, law enforcement, media, litigation, remediation, etc,; the cost and damages of a breach are made worse.  Contact your DSU Consultant who can help you quickly put together the right Incident Response Plan for your organization.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "14",
          Question:
            "Do you have a Data Recovery policy in place which requires that full and functioning data backups are routinely and frequently made and that these backups are maintained in a secure offsite location?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that you may not have an appropriate Data Recovery process which covers the management of full data backups. More organizations are recognizing the vital need for backing up important data as a part of a proactive Data Recovery Process. However, organizations should not lose sight of the fact that information on backup tapes or disks are also a common target for data thieves.  Most importantly, strong Data Recovery plans require backups to be made to a very secure offsite location.  Let a DSU Consultant help you quickly prepare a customized Data Recovery Plan that fits your organization's specific needs.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "15",
          Question:
            "Do you conduct Vulnerability Scans at least as often as on a quarterly basis?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that you may not be implementing quarterly network scans. Strong Cyber Security practices follow PCI-DSS rules requiring organizations to complete at least four quarterly network scans, performed by an Approved Scanning Vendor (ASV). If you are not engaging the services of an ASV to complete quarterly network scans, you are non-compliant with PCI-DSS requirements and failing to follow strong Cyber Security Practices.  Call on a DSU consultant to ensure that you have a vetted ASV who will  provide quarterly network scans at reduced prices.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "16",
          Question: "Are Penetration Tests conducted periodically?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that your organization may not be conducting regular Penetration tests.  Penetration tests enable an organization to probe for weaknesses in their own system infrastructure, including hardware and software applications, in order to shore up these network vulnerabilities and develop tighter controls. As Penetration tests should play a vital role in any organization's cybersecurity defenses, have a DSU Consultant help you conduct Penetration tests at DSU discounted pricing.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "17",
          Question: "Do you maintain a wireless Computer Network?",
          "Keep / Retire": "",
          best_answer: "No",
          best_answer: "Yes or IDK",
          explanation: "No Action Item",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "18",
          Question:
            "Have you installed and activated properly configured software and hardware firewalls on all your business networks?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No, IDK, What's a Firewall?",
          explanation:
            "You indicated that you either do not, or do not know if you maintain, a properly configured firewall. PCI-DSS rules require merchants to install and maintain a firewall and router configuration to protect cardholder data. Firewall capability is often built into other network devices, such as routers, which control the flow of data into and out of your network. If you do not maintain, or do not know if you maintain a properly configured firewall, consult with your DSU Consultant to ensure the right firewalls are being installed at value pricing.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "19",
          Question: "Do you use a VPN Router to transmit data between devices?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that you may not be using a Virtual Private Network (VPN) router. A VPN router is a particular type of router that encrypts data, such as customer credit card data, as it passes through a network. Strong Cyber Security practices conform with PCI-DSS rules that require organizations to encrypt sensitive company data and cardholder data as it is transmitted across open, public networks. As not all VPNs are created equal - ask your DSU Consultant about what reputable VPN models to use at discounted vendor prices.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "20",
          Question:
            "Is all traffic through firewalls, routers, etc. logged by an audit log tool so that your system administrator is able to detect unusual or irregular activity within your network?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that there may not be an audit log process and tool in place to detect unusual activity on or through your network's firewalls or routers. As spotting potentially troublesome network traffic activity may be critical to detecting a hacker and time is of the essence - get help from from your DSU Consultant  to install the right audit log tool and alert controls at discounted prices.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "21",
          Question:
            "Has your organization implemented Point-to-Point encryption for data in transit?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that you may not be implementing all of the primary technology tools that are required to best safeguard your company from a security breach. Point-to-Point encryption best secures against hackers during data transmission.  Have a DSU Consultant see that you have this vital technology tool at a DSU discounted rate.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "22",
          Question:
            "Has your organization implemented Tokenization for data at rest?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that you may not be implementing all of the primary technology tools that are required to best safeguard your company from a security breach. Tokenization best secures data at rest.  Have a DSU Consultant see that you have this vital technology tool at a DSU discounted rate.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "23",
          Question:
            "Does your organization accept credit cards which would subject it to compliance with Payment Card Industry Data Security Standard (PCI-DSS)?",
          "Keep / Retire": "",
          best_answer: "No",
          best_answer: "Yes",
          explanation: "",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "24",
          Question:
            "(If Yes- use these three)* Have you implimented EMV e.g. Chip & Pin/Chip & Signature?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that you may not be implementing all of the primary technology tools that are required to best safeguard your company from a security breach. Merchants should make the necessary investment in EMV credit card readers for their POS systems to better guard against counterfeit and stolen cards. Call on your DSU Consultant to make sure that you receive your discounted EMV credit card reader from a reputable and vetted vendor.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "25",
          Question:
            "Do you use current Anti-Virus Software, and deploy Patch Management procedures for your computer systems?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that you do not, or do not know if you use updated Anti-Virus software or deploy Patch Management procedures. Both the NIST Cyber Security Framework and PCI-DSS rules require organizations to use and regularly update anti-virus software or programs. The requirements that organizations regularly update anti-virus software and ensure that all software and hardware have the latest security updates (patches) are vital as hackers constantly develop new viruses and malware to exploit vulnerabilities in target organization's networks.   Call on a DSU Consultant  If to should you have any questions on how to ensure that your organization has updated anti-virus software and security patches.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "26",
          Question: "Do you still use Microsoft Windows XP or Windows 7?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated you may still be using Windows XP which Microsoft ceased providing security updates for on April 8, 2014. As a result, your data has become unprotected on this old operating system. We understand it is natural to want to save money by hanging onto old equipment. However, the financial risk of keeping Windows XP is much too great. It is necessary to upgrade ASAP.  Your DSU Consultant is available to help you make this easy and economical transition.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "27",
          Question:
            "Does your company still have security applications that use either Secure Sockets Layer (SSL) or Transport Layer Security (TLS) v1.0?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "Proper Cyber Security practices conform to the PCI-DSS requirement which requires the removal of Secure Sockets Layer SSLv3 security protocols technology and Transport Layer Security TLS v 1.0 past June 30, 2018. Prior to this date, merchants were required to establish a formal PCI-DSS Risk Mitigation Plan to address existing implementations that use SSL and/or early TLS. Let your DSU Consultant help you put together the required Risk Mitigation Plan.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "28",
          Question:
            "Has your organization installed web filters and email filters as a means to help secure your network from Web-based threats?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that your organization may not be fully utilizing either email filters or web filters.   Filters are required to better ensure that employees will not have unfettered access to the internet which would pose a grave risk to your organization's data security.  Whereas email filters work with firewalls to act as a first defense to hackers' phishing attacks, web filters guard against fraudulent or websites whose content is aggregated from other sites.  If just one feed of data on a website has been compromised, all the websites that pull in from that feed will provide malware to the user unless web filters have been applied.   Your DSU Consultant is available to help you with the purchase and implementation of both email and web filters.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "29",
          Question:
            "Does your organization use a third-party data storage or cloud storage solutions?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation: "No Action Plan",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "30",
          Question:
            "(If Yes)* Have you ensured with your Cloud Service Provider (CSP) that the risk factors involved with 3rd party data storage are being mitigated by encryption, secure access, data recovery/backup or mirroring?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "You indicated that your organization may not have required from their Cloud vendor the requisite comfort that their Cloud vendor is applying the highest standards of care in protecting your business data. Many people do not sufficiently understand what Cloud computing does and incorrectly assume that data placed in 'the Cloud' is adequately secured. The fact is that the types of security risks (e.g. security, integrity, availability, and performance) that exist in a non-cloud environment are also evident with cloud based technology solutions. Additionally, Cloud Service Providers (CSPs) are considered to be high value targets by hackers as they contain a consolidation of data from multiple organizations.  Check with your DSU Consultant on how best to ensure that your Cloud vendor is securely protecting your company's data.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
        {
          ID: "31",
          Question:
            "Does your insurance provide coverage for Data Security Breaches?",
          "Keep / Retire": "",
          best_answer: "Yes",
          best_answer: "No or IDK",
          explanation:
            "It would be reckless for any organization to go without Cyber Security insurance given the enormous risk and damages associated with ransomware and cyber breaches.  Of course, it is necessary to do much more than simply signing up for an insurance policy as you may find out too late that coverage exemptions and exclusions have made your policy useless.  Have a DSU Consultant help to ensure that you have a comprehensive loss policy that is tailored to your company's operations and needs.",
          "Criticality Measure": "",
          "Solution Provider Bucket": "",
          Notes: "",
        },
      ];
      return questions;
    }
  },
  async getAssessmentQuestions1(type) {
    type = type.toLowerCase();
    let data = await qList.GetQuestions(type);

    return data;
  },
  getScoreDefinitions() {
    const definitions = {
      highest:
        "A grade of Highest Risk denotes a total lack of familiarity with PCI-DSS requirements, as well as the common network security measures implemented to prevent data security breaches. Immediate action should be taken to increase your awareness of PCI-DSS requirements and safeguard your network from infiltration by hackers and other criminals. Your customers’ credit card data may be at risk. Below are suggested action items you can take to increase your knowledge and better protect your network from a data security breach.",
      high: "",
      medium: "",
      low: "",
      lowest: "",
    };
    return definitions;
  },
  getActionItemIcon(item) {
    const filename = `../images/pci_action_icons/${item}.jpg`;
    const imgFile = require(filename);
  },
  getRiskThresholds() {
    const risk_threshold = {
      low: 0.03125,
      moderate: 0.09375,
      vulnderable: 0.28125,
      high: 0.4875,
      highest: 0.71875,
    };
    return risk_threshold;
  },
  getCountOfEach(counts) {
    var countOfEach = {};
    for (const num of counts) {
      countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
    }
    return countOfEach;
  },
  calculateRisk(counts, countOfEach, risk_threshold) {
    let unsureTotal = countOfEach["unsure"];
    let noTotal = countOfEach["no"];
    let yesTotal = countOfEach["yes"];
    if (
      isNaN(unsureTotal) ||
      unsureTotal === "undefined" ||
      unsureTotal === "" ||
      unsureTotal === null
    ) {
      unsureTotal = 0;
    }

    if (
      isNaN(noTotal) ||
      noTotal === "undefined" ||
      noTotal === "" ||
      noTotal === null
    ) {
      noTotal = 0;
    }

    if (
      isNaN(yesTotal) ||
      yesTotal === "undefined" ||
      yesTotal === "" ||
      yesTotal === null
    ) {
      yesTotal = 0;
    }

    // let newNoTotal = noTotal + unsureTotal;
    let noPercent = (noTotal + unsureTotal) / counts.length;

    let risk = "Low";
    if (noPercent === NaN) {
      noPercent = 0;
    }
    if (noPercent > risk_threshold["highest"]) {
      risk = "Highest";
      //  //.71875
    } else if (noPercent > risk_threshold["high"]) {
      risk = "High";
      //  //.46875
    } else if (noPercent > risk_threshold["vulnerable"]) {
      risk = "Vulnerable";
      //  //.28125
    } else if (noPercent > risk_threshold["moderate"]) {
      risk = "Moderate";
      //  //.09375
    } else if (noPercent > risk_threshold["low"]) {
      risk = "Low";
      //  //.03125
    } else if (isNaN(noPercent)) {
      risk = "Unknown";
      //  //.03125
    }
    //
    return risk;
  },
  async getAssessmentHistory(guestProfile) {
    let profile = await localStorage.getItem("guestProfile");

    if (profile !== null && Object.keys(profile).length > 0) {
      //
      let data = {
        user: profile,
      };

      // debugger;
      try {
        let token = _auth.getUserToken("jwtToken");
        setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
        let response = await postRequest("user-assesment-history", data);
        // debugger;
        if (response && response.data && response.status == 200) {
          return response.data;
        } else {
          return [];
        }
      } catch (e) {
        return [];
      }
    } else {
      let profile = _auth.getUserProfile();
      //
      let data = {
        user: profile,
      };

      try {
        //debugger;
        let token = _auth.getUserToken("jwtToken");
        setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
        let response = await postRequest("user-assesment-history", data);
        //debugger;

        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.status == 200
        ) {
          return response.data.data;
        } else {
          return [];
        }
      } catch (e) {
        return [];
      }
    }
  },

  async getAllAssessmentHistory(guestProfile) {
    let profile = await localStorage.getItem("guestProfile");

    if (profile !== null && Object.keys(profile).length > 0) {
      //
      let data = {
        user: profile,
      };

      // debugger;
      try {
        let token = _auth.getUserToken("jwtToken");
        setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
        let response = await postRequest("all-assesment-history", data);
        // debugger;
        if (response && response.data && response.status == 200) {
          return response.data;
        } else {
          return [];
        }
      } catch (e) {
        return [];
      }
    } else {
      let profile = _auth.getUserProfile();
      //
      let data = {
        user: profile,
        companyId: profile.tenantCompanyId,
        tenantId: profile.tenantId,
      };

      try {
        //debugger;
        let token = _auth.getUserToken("jwtToken");
        setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
        let response = await postRequest("all-assesment-history", data);
        //debugger;

        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.status == 200
        ) {
          return response.data.data;
        } else {
          return [];
        }
      } catch (e) {
        return [];
      }
    }
  },

  async getEmployeeAssessmentHistory(data) {
    let body = {
      employeeId: data._id,
      tenantId: data.tenantId,
    };

    // debugger;

    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("get-employee-assessments", body);
    // debugger;

    if (response) {
      return response.data.data;
    } else {
      return [];
    }
    // }
  },

  async getAssessmentById(id) {
    let profile = _auth.getUserProfile();
    //
    let data = {
      id: id,
      user: profile,
    };

    // try {

    //debugger;
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("assessments/get-assessment-by-id", data);

    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.status == 200
    ) {
      return response.data.data;
    } else {
      return [];
    }
    // } catch (e) {
    //   return [];
    // }
  },

  getJsonTemplate() {
    var template = require("./assessment_history_model.json");
    template = JSON.stringify(template);

    return template;
  },
  updateAssessmentObject() {},
  updateAssessmentHistory() {},

  async setAssessmentHistory(history, guest) {
    try {
      this.loader = true;
      //debugger;

      let response;
      // debugger;
      if (guest) {
        response = await postRequest("create-assessmentV1-guests", history);
      } else {
        let profile = _auth.getUserProfile();
        history.company_id = profile.tenantCompanyId;
        history.user_id = profile.user_id;

        response = await postRequest("create-assessmentV1", history);
      }
      //debugger;

      if (response && response.data && response.data.status == 200) {
        //debugger;
        this.loader = false;
        return response.data.data;
      } else {
        //debugger;
        return [];
      }
    } catch (e) {
      //debugger;

      return [];
    }
  },
  async sendAssessment(emails, type) {
    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        emails: emails,
        type: type,
        tenantId: _auth.getUserProfile().tenantId,
      };

      let response = await postRequest("send-assessment", data);
      if (response && response.data) {
        //debugger;
        this.getGuestList();
        return true;
      } else {
        //debugger;
        return false;
      }
    } catch (e) {
      //debugger;
      return false;
    }
  },
  async getGuestList() {
    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await getRequest("guest-list");

      if (response && response.data) {
        return response.data;
      } else {
      }
    } catch (e) {}
  },
  // below this line is new code for creating, updating, deleting templates

  async createTemplate() {
    // try {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("/assessments/templates/create");
    console.log("createTemplate() response", response);
    if (response && response.data) {
      return response.data.data.data;
    } else {
    }
    // } catch (e) {

    // }
  },
  async saveTemplate(template) {
    try {
      delete template._id;
      template.status = "draft";
      let body = { update: template };

      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("/assessments/templates/save", body);
      if (response && response.data) {
        return response.data.data.data;
      } else {
      }
    } catch (e) {}
  },
  async getTemplates() {
    try {
      // return (
      //   /**
      //    * Paste one or more documents here
      //    */
      //   [
      //     {
      //       id: 6,
      //       name: "Sample",
      //       description: "Short assessment for demo purposes",
      //       key: "sample",
      //       status: "deleted",
      //       intro: "This was a sample assessment",
      //       allowCompanyVisibility: true,
      //       companyId: null,
      //       tenantId: "",
      //       audience: ["employees, vendors, admins"],
      //       category: "Risk",
      //       type: "System",
      //       actionPlanRequired: null,
      //       updatedAt: {
      //         $date: {
      //           $numberLong: "1670963378450",
      //         },
      //       },
      //       blurbs: {
      //         High: "  Congratulations on finishing the CyberSecurity Risk Assessment. It’s a great first step in the journey to becoming as Cyber Secure as possible. We strongly urge you to focus on fixing all the areas highlighted in your organization's customized Action Plan because every vulnerability is important and leaves your company exposed. If you need help, DSU makes it quick and easy to find the right vendors without the hassle. We've partnered with the industry's most reputable solution providers to provide discounted solutions for you. You're one click away from connecting with vendors who are committed to making your life easier while working to safeguard your organization.",
      //         Normal:
      //           "Congratulations on your Perfect Score. After you take a well deserved victory lap, use DSU to assess other areas of your organization's cyber readiness, train the rest of your organization, and assess and train third party vendors and contractors so that they can help secure your organization.  Cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations, but you can count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
      //         Low: "Sample L",
      //       },
      //       marketplace_mapping: [],
      //     },
      //     {
      //       id: 6,
      //       name: "Sample",
      //       description: "Short assessment for demo purposes",
      //       key: "sample",
      //       status: "deleted",
      //       intro: "This was a sample assessment",
      //       allowCompanyVisibility: true,
      //       companyId: null,
      //       tenantId: "",
      //       audience: ["employees, vendors, admins"],
      //       category: "Risk",
      //       type: "System",
      //       actionPlanRequired: null,
      //       updatedAt: {
      //         $date: {
      //           $numberLong: "1670963378450",
      //         },
      //       },
      //       blurbs: {
      //         High: "  Congratulations on finishing the CyberSecurity Risk Assessment. It’s a great first step in the journey to becoming as Cyber Secure as possible. We strongly urge you to focus on fixing all the areas highlighted in your organization's customized Action Plan because every vulnerability is important and leaves your company exposed. If you need help, DSU makes it quick and easy to find the right vendors without the hassle. We've partnered with the industry's most reputable solution providers to provide discounted solutions for you. You're one click away from connecting with vendors who are committed to making your life easier while working to safeguard your organization.",
      //         Normal:
      //           "Congratulations on your Perfect Score. After you take a well deserved victory lap, use DSU to assess other areas of your organization's cyber readiness, train the rest of your organization, and assess and train third party vendors and contractors so that they can help secure your organization.  Cyber criminals are always coming up with new and creative ways to steal your data and shut down your company's operations, but you can count on DSU to provide you with all of the tools and training your organization needs to stay ahead of the Bad Guys!",
      //         Low: "Sample L",
      //       },
      //       marketplace_mapping: [],
      //     },
      //   ]
      // );
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("assessments/templates/get");
      if (response && response.data) {
        return response.data.data.data;
      } else {
      }
    } catch (e) {}
  },
  async updateTemplate(template) {
    let body = {
      updates: template,
    };
    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("assessments/templates/update", body);
      if (response && response.data) {
        return response.data.data.data;
      } else {
      }
    } catch (e) {}
  },
  async deleteTemplate(template) {
    let body = {
      _id: template._id,
      key: template.key,
    };
    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("assessments/templates/delete", body);
      if (response && response.data) {
        return response.data.data.data;
      } else {
      }
    } catch (e) {}
  },
  async updateTemplateQuestions(template, id) {
    try {
      let body = {
        _id: id,
        updates: template,
      };
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "assessments/templates/update-questions",
        body
      );
      if (response && response.data) {
        return response.data.data.data;
      } else {
      }
    } catch (e) {}
  },
  async updateTemplateQuestionsByType(template, id) {
    try {
      let body = {
        _id: id,
        updates: template,
      };
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "assessments/templates/update-questions-by-type",
        body
      );
      if (response && response.data) {
        return response.data.data.data;
      } else {
      }
    } catch (e) {}
  },
  async addNewQuestion(template) {
    try {
      let profile = _auth.getUserProfile();
      let tenant_id = profile.tenantId;
      template.tenant_id = tenant_id;
      let body = {
        updates: template,
      };

      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "assessments/templates/questions/add-new-question",
        body
      );
      if (response && response.data) {
        return response.data.data.data;
      } else {
      }
    } catch (e) {}
  },
  async deleteQuestion(id) {
    try {
      let body = {
        _id: id,
      };
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(
        "assessments/templates/questions/delete-question",
        body
      );
      if (response && response.data) {
        return response.data.data.data;
      } else {
      }
    } catch (e) {}
  },

  //above this line is new code for creating, updating, deleting templates
};
