import { _assessment } from "../modules/_assessment";
import { assessments } from "../modules/assessments";
import utils from "./_utils";
import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";

export const _dashboard = {
  async getBurndownData(admin) {
    let profile = _auth.getUserProfile();

    // try {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let body = {
      tenantId: profile.tenantId,
      company_id: profile.tenantCompanyId,
      admin: admin,
    };

    let response = await postRequest(
      "/dashboard/get-impediment-burden-history",
      body
    );

    if (response) {
      return response.data.data;
    } else {
      return [];
    }
    // } catch (e) {
    //   return [];
    // }
  },
  async getGlobalScoresByCompetency() {
    let profile = _auth.getUserProfile();

    // try {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let body = {
      tenantId: profile.tenantId,
      companyId: profile.tenantCompanyId,
    };

    let response = await postRequest(
      "/competencies/get-avg-by-competency",
      body
    );

    if (response) {
      return response.data.data.resArray;
    } else {
      return [];
    }
    // } catch (e) {
    //   return [];
    // }
  },

  convertDates(data) {
    let tempArr = [];
    Object.entries(assessments.getDateTotals(data)).forEach((date) => {
      var d = new Date(date[0]),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let newDate = [month, day, year].join("-");

      let total = date[1];

      if (newDate != "Invalid Date" && date[0] != undefined) {
        tempArr.push([newDate, total]);
      }
    });
    return tempArr;
  },
  todaysHistory(data) {
    let todaysHistory = data.filter((item) => {
      return (
        item.assessment_date ===
        new Date().toLocaleDateString("en-us", {
          // weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
    });
    return todaysHistory;
  },
  calculateAverageRisk(data) {
    let countOfEach = this.getCountOfEach(data);

    let avgRisk =
      Object.keys(countOfEach).length > 0
        ? Object.keys(countOfEach).reduce((a, b) =>
            countOfEach[a] > countOfEach[b] ? a : b
          )
        : null;

    return avgRisk;
  },
  getCountOfEach(data) {
    const riskArray = [];
    data.length > 0 &&
      data.map((item) => {
        item.risk_score != null &&
          item.risk_score != "null" &&
          riskArray.push(item.risk_score);
      });

    const countOfEach = {};
    for (const num of riskArray) {
      countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
    }
    return countOfEach;
  },
  getUnfinishedToday(data) {
    const unfinishedToday =
      data.length > 0 &&
      data.filter((item) => {
        return item.risk_score === "null";
      });
    return unfinishedToday;
  },
  getAllUnfinishedAssessments(data) {
    let unfinished =
      data.length > 0 &&
      data.filter((item) => {
        return item.risk_score === "null";
      });
    return unfinished;
  },
  getTotalCustomers() {
    return 1000;
  },
  getTotalCustomerHistory() {
    return [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  },
  getTotalCustomers() {
    let data = getRequest("get-total-customers");
    if (data) {
      return data;
    }
  },
  getTotalCustomersHistory() {
    let data = getRequest("get-total-customers-history");
    if (data) {
      return data;
    }
  },
  getTotalUsers() {
    let data = getRequest("get-total-users");
    if (data) {
      return data;
    }
  },
  getTotalUsersHistory() {
    let data = getRequest("get-total-users-history");
    if (data) {
      return data;
    }
  },
  getTodaysActiveUsers() {
    let data = getRequest("get-todays-active-users");
    if (data) {
      return data;
    }
  },
  getDailyActiveUsers() {
    let data = getRequest("get-daily-active-users");
    if (data) {
      return data;
    }
  },
  getYeseterdaysActiveUsers() {
    let data = getRequest("get-yeseterdays-active-users");
    if (data) {
      return data;
    }
  },
  getActiveUserHistory() {
    return [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  },
  getTopUsers() {
    let data = getRequest("get-top-users");
    if (data) {
      return data;
    }
  },
};
