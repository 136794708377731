import React from "react";
import { Link } from "react-router-dom";
import LineChart from "../../charts/LineChart01";
import Icon from "../../images/icon-01.svg";
import EditMenu from "../../components/DropdownEditMenu";
import { NavLink } from "react-router-dom";
// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";

import Skeleton from "../../components/Skeleton";
import RightActionArea from "../../components/ActionAreaRight";
import QuestionToolTip from "../../components/QuestionToolTip";
import Button from "../../components/Button";

function DashboardCard01(props) {
  const loading = props.loading;
  const title = props.title;
  const teamCount = props.teamCount;
  const burdenSummary = props.burdenSummary;
  const subTitle = props.subTitle ? props.subTitle : null;
  const data = props.data;
  const unfinished = props.unFinishedAssessments;
  const scores = props.scores;
  const effort = props.effort;
  const total = props.total;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const showLink = props.showLink ? props.showLink : false;
  const onLinkClick = props.onLinkClick;
  const shadow = props.shadow ? props.shadow : true;

  console.log(
    `Title: ${title} Data: ${data} TeamCount: ${teamCount} Unfinished: ${unfinished} Scores: ${scores} Effort: ${effort} Total: ${total} Rowspan: ${rowspan} Colspan: ${colSpan} ShowLink: ${showLink} Shadow: ${shadow}`
  );

  return (
    <div
      className={`flex flex-col justify-between grow-0 sm:col-span-full md:col-span-12 md:col-span-12  xl:col-span-${colSpan}  grow-0 row-span-${rowspan} bg-white shadow-lg rounded-sm border border-gray-200`}
    >
      <div className="px-5 pt-5 pb-5">
        <header className="flex grid grid-flow-col  ">
          <div className="inline-flex w-fit align-middle  text-lg font-semibold text-slate-800 mb-2 ml-0">
            {props.title}
            {subTitle && (
              // <RightActionArea>
              <div className="ml-2 inline-flex pt-1 text-xs font-medium  text-slate-500">
                ({subTitle})
              </div>
              // </RightActionArea>
            )}
          </div>

          {/* Menu button */}
          {/* <EditMenu align="right" className="relative inline-flex">
            <li>
              <Link
                className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                to="#0"
              >
                Option 1
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                to="#0"
              >
                Option 2
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3"
                to="#0"
              >
                Remove
              </Link>
            </li>
          </EditMenu> */}
        </header>
        {loading ? (
          // <Skeleton type="spinner" color="gray" fill="purple" />
          <Skeleton type="spinner" />
        ) : (
          <div className="grow h-full">
            <div className="flex flex-col h-full">
              <div className="flex items-start">
                <div className="  text-3xl font-bold text-slate-800 mr-3 mb-2">
                  {data}
                </div>

                {unfinished && data != null && (
                  <div className="text-sm font-semibold text-white px-1.5 bg-accent-500 rounded-full">
                    {unfinished}
                    {props.title == "Expert Answers"
                      ? "% of questions"
                      : "in progress"}
                  </div>
                )}
                {effort && data != null && (
                  <div className=" text-xs font-medium text-white px-1.5 bg-red-700 px-3 rounded-full">
                    {effort} days of effort
                  </div>
                )}
                {scores && data != null && (
                  <div className="inline-flex sm:hidden md:hidden lg:hidden ml-2 text-sm font-semibold text-white px-1.5 bg-red-500 rounded-full">
                    {scores.High ? scores.High + " High" : null}
                  </div>
                )}
              </div>
              {burdenSummary && (
                <>
                  <div className=" items-start justify-start font-medium text-xs px-2 p-3">
                    First year potential savings:{" "}
                    {burdenSummary["First year savings"] &&
                    burdenSummary["First year savings"] > 0 ? (
                      burdenSummary["First year savings"].toFixed(2)
                    ) : (
                      <>
                        {burdenSummary["First year savings"] &&
                          burdenSummary["First year savings"].toFixed(2)}{" "}
                        days{" "}
                        <QuestionToolTip
                          field={"First year savings"}
                          text={
                            "First year potential savings is negative due to the effort required to resolve the reported burden. This number currently includes Organization-level impediments which may skew the results.  We are working on a filter to exclude these impediments from the calculation."
                          }
                        ></QuestionToolTip>
                      </>
                    )}
                    {/* <LineChart data={chartData} width={389} height={128} /> */}
                  </div>
                  <div className=" items-start justify-start font-medium text-xs px-2 p-0">
                    Savings after breakeven:{" "}
                    {burdenSummary["First year savings"] &&
                    burdenSummary["First year savings"] > 0 ? (
                      burdenSummary["First year savings"].toFixed(2)
                    ) : (
                      <>
                        {burdenSummary["Future year savings (days)"] &&
                          burdenSummary["Future year savings (days)"].toFixed(
                            2
                          )}{" "}
                        days/yr{" "}
                        <QuestionToolTip
                          field={"Future year savings (days)"}
                          text={`
                        This number starts after you break even, currently at ${burdenSummary["Break even point"]} effort weeks.
                      `}
                        ></QuestionToolTip>
                      </>
                    )}
                    {/* <LineChart data={chartData} width={389} height={128} /> */}
                  </div>
                </>
              )}
              {teamCount && (
                <div className=" items-start justify-start font-medium text-xs px-2 p-3">
                  {props.title == "Competency Score" && (
                    <span>
                      Based on {teamCount} completed assessments
                      <QuestionToolTip
                        field={"Completed Assessments"}
                        text={"Based on the latest completed assessments."}
                      ></QuestionToolTip>
                    </span>
                  )}
                  {props.title == "Expert Answers" && (
                    <span>
                      Out of {teamCount} answered questions.
                      <QuestionToolTip
                        field={props.title}
                        text={"Based on all answered questions by all teams."}
                      ></QuestionToolTip>
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {props.showLink && props.showLink == true && (
        <div className="flex items-center justify-end text-center px-6 py-2   border-t border-gray-100">
          {props.showLink && props.showLink == true && (
            <span
              onClick={onLinkClick}
              className="cursor-pointer text-sm font-medium text-accent-600 hover:text-accent-700"
            >
              See All
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default DashboardCard01;
