import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import Button from "../components/Button";
import SubscriptionListItem from "./SubscriptionListItem";
import { Icon } from "@iconify/react";
import TierListItem from "./TierListItem";

const frequencies = [
  {
    value: "monthly",
    stripe: "month",
    label: "Monthly",
    priceSuffix: "/month",
  },
  {
    value: "quarterly",
    stripe: "quarter",
    label: "Quarterly",
    priceSuffix: "/3 months",
  },
  {
    value: "annually",
    stripe: "year",
    label: "Annually",
    priceSuffix: "/year",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingTiers(props) {
  const {
    onClick,
    selectedTier,
    setSelectedTier,

    selectedProductId,
    selectedPriceId,
    selectedFrequency,
  } = props;
  const [frequency, setFrequency] = useState();
  const [tiers, setTiers] = useState([]);
  const [freeTier, setFreeTier] = useState({});

  useEffect(() => {
    let selectedFreq = frequencies.find(
      (freq) => freq.stripe === selectedFrequency
    );
    setFrequency(selectedFreq);
  }, [selectedFrequency]);

  useEffect(() => {
    setTiers(props.tiers);
    setFreeTier(props.freeTier);
  }, [props.tiers, props.freeTier]);

  //filter tier.prices to get the price based on the selected frequency
  //store the selected price in the tier.price property
  // useEffect(() => {
  //   if (tiers) {
  //     let tmp = tiers.map((tier) => {
  //       let price = tier.prices.find(
  //         (p) => p.recurring.interval === frequency.value
  //       );
  //       return {
  //         ...tier,
  //         price: {
  //           monthly: price.unit_amount / 100,
  //           annually: (price.unit_amount / 100) * 12,
  //         },
  //       };
  //     });
  //     setTiers(tmp);
  //   }
  // }, [frequency]);

  return (
    <div className="bg-white ">
      <div className="mt-0 py-2 flex w-full ">
        <div className="flex items-center w-1/2 justify-end">
          {props.selectTier && (
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-3 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              <RadioGroup.Label className="sr-only">
                Payment frequency
              </RadioGroup.Label>
              {frequencies.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? "bg-indigo-600 text-white" : "text-gray-500",
                      "cursor-pointer rounded-full px-2.5 py-1"
                    )
                  }
                >
                  <span>{option.label}</span>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          )}
        </div>
        <div className="flex items-center w-1/2 justify-end">
          {props.selectTier && (
            <Button
              rounded
              color="emerald"
              handleClick={() => {
                props.setIsBackClicked(false);
                props.setCurrentStep("compare-plans");
              }}
            >
              Compare Plans
            </Button>
          )}
        </div>
      </div>
      {props.selectTier ? (
        <>
          <div>
            {tiers &&
              tiers.map((tier, index) => (
                <TierListItem
                  icon={
                    <Icon
                      icon="flat-color-icons:process"
                      className=" h-[42px] w-[42px]"
                    />
                  }
                  frequency={frequency}
                  item={tier}
                  selected={
                    selectedTier?.id === tier.id &&
                    selectedFrequency === frequency.stripe
                      ? true
                      : false
                  }
                  itemName={tier.name}
                  renewalDate={tier.description}
                  productName={`$${tier.price[frequency.value]}${
                    frequency.priceSuffix
                  }`}
                  price=""
                  numberOfProducts=""
                  buttonText={
                    //if the addon is already selected, show "Selected" else show "Select Feature"
                    selectedTier?.id === tier?.id ? "Added" : "Add Feature"
                  }
                  showButton={false}
                  buttonColor="text"
                  buttonIcon={(() => {})()}
                  buttonIconSelected="flat-coloricons:template"
                  onClick={(e) => {
                    console.log("Select Feature");
                    setSelectedTier(frequency, tier);
                  }}
                />
              ))}
          </div>
          <section className="pt-2">
            <div
              className={classNames(
                selectedTier?.id === freeTier?.id
                  ? "ring-2 ring-gray-400"
                  : "ring-1 ring-gray-100",
                "m-2 px-5 py-3 bg-gray-50 border border-gray-100 rounded-sm text-center xl:text-left xl:flex xl:flex-wrap xl:justify-between xl:items-center"
              )}
            >
              <div className="text-slate-800 font-semibold mb-2 xl:mb-0">
                Just need to respond to bid requests and nothing else?
              </div>
              <Button
                className="btn bg-primary-500 hover:bg-primary-600 text-white"
                handleClick={() => {
                  props.setSelectedTier(frequency, freeTier);
                }}
              >
                {freeTier?.name} Free
              </Button>
            </div>
          </section>
        </>
      ) : (
        <div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mt-0 flex justify-between">
              <div className="flex items-center justify-start">
                <Button
                  handleClick={() => {
                    props.setIsBackClicked(true);
                    props.setCurrentStep("manage");
                  }}
                  // color="text-secondary"
                  color="text-secondary"
                  rounded
                >
                  <Icon icon="mingcute:left-fill" className="h-5 w-5" />{" "}
                  <span className="text-base">Back</span>
                </Button>
              </div>
              <div className="flex items-center w-1/2 justify-center">
                <RadioGroup
                  value={frequency}
                  onChange={setFrequency}
                  className="grid grid-cols-3 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
                >
                  <RadioGroup.Label className="sr-only">
                    Payment frequency
                  </RadioGroup.Label>
                  {frequencies.map((option) => (
                    <RadioGroup.Option
                      key={option.value}
                      value={option}
                      className={({ checked }) =>
                        classNames(
                          checked
                            ? "bg-indigo-600 text-white"
                            : "text-gray-500",
                          "cursor-pointer rounded-full px-2.5 py-1"
                        )
                      }
                    >
                      <span>{option.label}</span>
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              </div>
              <div className="flex items-center justify-end"></div>
            </div>

            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className={classNames(
                    selectedTier?.id === tier.id
                      ? "ring-2 ring-indigo-600"
                      : "ring-1 ring-gray-200",
                    "rounded-3xl p-8 xl:p-10"
                  )}
                >
                  <div className="flex items-center justify-between gap-x-4">
                    <h3
                      id={tier.id}
                      className={classNames(
                        tier.mostPopular ? "text-indigo-600" : "text-gray-900",
                        "text-lg font-semibold leading-8"
                      )}
                    >
                      {tier.name}
                    </h3>
                    {tier.mostPopular ? (
                      <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                        Most popular
                      </p>
                    ) : null}
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-600">
                    {tier.description}
                  </p>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      {tier.price[frequency.value]}
                    </span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      {frequency.priceSuffix}
                    </span>
                  </p>
                  {/* <Button
                    color="secondary"
                    fullWidth
                    handleClick={() => onClick(tier)}
                    aria-describedby={tier.id}
                    className={classNames(
                      selectedTier?.id === tier.id
                        ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                        : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                      "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    )}
                  >
                    Select this plan
                  </Button> */}
                  <ul
                    role="list"
                    className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* FAQs */}
          <section>
            <div className="my-8">
              <h2 className="text-2xl text-slate-800 font-bold">FAQs</h2>
            </div>
            <ul className="space-y-5">
              <li>
                <div className="font-semibold text-slate-800 mb-1">
                  I'm your favorite vendor. Do I really need to pay for this?
                </div>
                <div className="text-sm">
                  Yes, you are our favorite vendor. We want to keep it that way.
                  But to do that, we have to stay in business. The only way we
                  can do that is by charging for our services. But don't worry,
                  you get the Friends and Family discount.
                </div>
              </li>
              <li>
                <div className="font-semibold text-slate-800 mb-1">
                  Is there any difference between Basic and Professional
                  licenses?
                </div>
                <div className="text-sm">
                  Of course there is. Otherwise, we couldn't charge for it. The
                  biggest difference is that the Basic plan is for basic people
                  and the Professional plan is for - you guessed it -
                  professionals!
                </div>
              </li>
              <li>
                <div className="font-semibold text-slate-800 mb-1">
                  Got more questions?
                </div>
                <div className="text-sm">
                  We have more cheeky answers that we care to admit so feel free
                  to{" "}
                  <a
                    className="font-medium text-primary-500 hover:text-primary-600"
                    href="#0"
                  >
                    contact us
                  </a>{" "}
                  and see what kind of answers we come up with.
                </div>
              </li>
            </ul>
          </section>
        </div>
      )}
    </div>
  );
}
