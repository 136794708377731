import axios from "axios";
import { baseUrl } from "../config/urlRoutes";

let headers = new Headers();
headers.append("Access-Control-Allow-Origin", "http://localhost:5000/api/");
headers.append("Access-Control-Allow-Credentials", "true");
// headers.append("X-Content-Type", "application/json; charset=utf-8");
// let token = _auth.getUserToken("jwtToken");;
//
// }

export const getRequest = async (url, params) => {
  try {
    let response = await axios.get(baseUrl + url, params, {
      headers: headers,
    });
    if (response) {
      return response;
    }
  } catch (e) {
    return e;
  }
};

export const postRequest = async (url, params) => {
  try {
    let response = await axios.post(baseUrl + url, params, {
      headers: headers,
    });
    //
    if (response) {
      return {
        data: response,
      };
    }
  } catch (e) {
    return e;
  }
};

export const signupPostRequest = async (
  url,
  params,
  successCallback,
  errorCallback
) => {
  try {
    let response = await axios
      .post(baseUrl + url, params, {
        headers: headers,
      })
      .then((response) => {
        successCallback(response.data);
      })
      .catch((error) => {
        errorCallback(error.response.data);
      });
    if (response) {
      return {
        data: response,
      };
    }
  } catch (e) {
    return e;
  }
};
