import React from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Logo from "../images/logo.webp.png";
import HeroImage from "../images/login-bg.webp";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import { usePersistStore } from "../store/usestore";
import Input from "../components/Input";
import Button from "../components/Button";

function ResetPassword() {
  const mvp = false;
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [msg, setMsg] = useState(false);
  const [passwordStrategy, setPasswordStaretegy] = useState("LINK");
  // const [isAuthenticated, setIsAuthenticated] = useState();
  const [loader, setLoader] = useState(false);

  const { isSuccess, forgotPasswordAsync, error, loginError, isAuthenticated } =
    usePersistStore();
  console.log(error, "error");
  const forgotPassword = async (event) => {
    setLoader(true);
    event.preventDefault();
    if (email == "") {
      setLoader(false);
      setErr("Please enter your email");
      return;
    } else {
      if (passwordStrategy === "LINK") {
        console.log("I aminside");
        forgotPasswordAsync(email, history, "LINK");
        setMsg(!msg);
      } else {
        forgotPasswordAsync(email, history, "OTP");
        if (isSuccess) {
          setLoader(false);
        } else {
          setLoader(false);
        }
      }
      // let profile = await _auth.forgotPassword(email,history)
    }
  };

  return (
    <main className="">
      <div className="absolute opacity-80 top-0 z-40 bg-none max-w-sm px-4 py-3">
        <div className="flex flex-row justify-center content-center">
          {/* <img width="80" className="mr-3 " src={Logo} /> */}
          <span className="inline-flex text-2xl m-auto font-medium text-white">
            Clairifi Security
          </span>
        </div>
      </div>
      <div className="bg-slate-900 flex flex-col grow justify-center items-center  h-full w-full absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-1">
          <img
            className="object-cover opacity-60 object-top w-full h-full xl:absolute xl:inset-0 top:0 left-0 right-0 bottom-0"
            src={HeroImage}
            alt=""
          />
          <div className="absolute inset-0 h-full w-full" />
        </div>
      </div>
      <div className="  relative grow-1 justify-center">
        {/* Content */}
        {/* <div className=" justify-center content-center"> */}
        {/*Removed divide-x below   */}
        <div className="grid grid-cols-1 lg:grid-cols-1 min-h-screen  flex grow-0 flex-col content-center justify-center">
          {/* Begin content split */}
          <div className=" flex flex-col h-screen justify-center content-center">
            {/* Left Side card*/}
            {/* Content */}
            <div className="rounded-lg mx-auto bg-white pb-12 max-w-2/3 px-6">
              <div className="flex max-w-sm mx-auto  ">
                <span className="flex items-center text-3xl font-normal text-slate-700">
                  <img width="80" className="mx-auto px-4 py-8" src={Logo} />
                  Clairifi Security
                </span>
              </div>

              <div className=" justify-center content-center">
                <div className=" h-full flex flex-col content-center justify-center">
                  <div className="rounded-lg mx-auto bg-white pb-12 max-w-2/3 px-6">
                    <div className="max-w-sm mx-auto px-4">
                      {/* <img className="max-w-18 mx-auto px-4 py-8" src={Logo} /> */}
                    </div>
                    <div className="max-w-sm mx-auto px-4 ">
                      {!msg && (
                        <h1 className="text-2xl text-slate-800 font-bold mb-6">
                          Forgot Password
                        </h1>
                      )}

                      {msg ? (
                        <>
                          <div className="space-y-4">
                            <div className="shadow rounded bg-gray-50 border border-gray-200 p-4 w-full  mt-6 text-black">
                              <div className="flex flex-col justify-start">
                                <div className="font-semibold mb-2">
                                  One last thing...
                                </div>
                                <div>
                                  Check your email. If an account exists, you
                                  will receive an email with a link to reset
                                  your password.
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <form onSubmit={forgotPassword}>
                          <div className="space-y-4">
                            <div>
                              <label
                                className="block text-sm font-medium mb-1"
                                htmlFor="password"
                              >
                                Registered Email
                              </label>
                              <Input
                                type="email"
                                placeholder="Enter your email"
                                id="email"
                                autoComplete="on"
                                onChange={(e) => setEmail(e.target.value)}
                                // value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="flex items-center justify-between mt-6">
                            {loader ? (
                              <div class="flex justify-center items-center">
                                <img
                                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                                  src={Spinner}
                                  width="32"
                                  height="32"
                                  alt="loading.."
                                />
                              </div>
                            ) : (
                              <Button
                                type="submit"
                                fullWidth
                                color="secondary"
                                disabled={email == "" ? true : false}
                                // class="btn bg-primary-500 hover:bg-primary-600 text-white ml-3"
                                // onClick={auth}
                              >
                                Verify Email
                              </Button>
                            )}
                          </div>
                        </form>
                      )}

                      {loginError === true && (
                        <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                          Not Registered
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
