let baseUrl = "";
if (process.env.REACT_APP_NODE_ENV === "development") {
  // baseUrl = "https://prod-api.clairifisecurity.com/api/";
  baseUrl = "http://localhost:7001/api/";
} else if (process.env.REACT_APP_NODE_ENV === "production") {
  baseUrl = "https://prod-api.clairifisecurity.com/api/";
} else if (process.env.REACT_APP_NODE_ENV === "beta") {
  baseUrl = "https://beta-api.clairifisecurity.com/api/";
}

export { baseUrl };
