import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { _auth } from "../modules/_auth";
import Logo from "../images/logo.webp.png";
import HeroImage from "../images/login-bg.webp";
import Spinner from "../images/spinner.png";
// import { utils } from "../modules/_utils";
import jwtDecode from "jwt-decode";

function ResetPassword(props) {
  const mvp = false;
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [otp, setOtp] = useState();
  const [err, setErr] = useState(false);
  const [tokenErr, setTokenErr] = useState(false);
  const [tokenErrMessage, setTokenErrMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token, id } = useParams();
  const [loader, setLoader] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);

  console.log(token, "token");

  useEffect(() => {
    (async () => {
      if (token) {
        let decodedToken = await jwtDecode(token);
        if (decodedToken) {
          console.log(decodedToken, "decoded-token");
          let checkIfValid = await _auth.verifyOtpToken(decodedToken, id);
          setPageLoad(false);
          if (checkIfValid.isValid === false) {
            let valid = checkIfValid.isValid;
            let message = checkIfValid.message;
            // setTokenErr(true);
            // setTokenErrMessage(checkIfValid.message);
          }
        }
      }
      // let checkIfValid = await _auth.verifyToken(token, id);

      // if (checkIfValid.isValid === false) {
      //   let valid = checkIfValid.isValid;
      //   let message = checkIfValid.message;
      //   setTokenErr(true);
      //   setTokenErrMessage(checkIfValid.message);
      // }
    })();
  }, []);

  const resetPassword = async (event) => {
    setLoader(true);
    event.preventDefault();
    console.info(event);
    let path = "/home";
    console.log(id);
    console.log(token);

    if (password !== confirmPassword) {
      setErr(true);
      setLoader(false);
    } else {
      let data = {
        id,
        token,
        password,
        confirmPassword,
      };
      let profile = await _auth.resetPasswordEmail(data, history);
      console.log(profile, "profile");
      if (profile === false) {
        setLoader(false);
        setIsAuthenticated(true);
      } else {
        setLoader(false);
        if (profile && profile.data) {
          history.push("/");
        } else {
          setIsAuthenticated(true);
        }
      }
    }
  };

  return (
    <main className="">
      <div className="absolute opacity-80 top-0 z-40 bg-none max-w-sm px-4 py-3">
        <div className="flex flex-row justify-center content-center">
          {/* <img width="80" className="mr-3 " src={Logo} /> */}
          <span className="inline-flex text-2xl m-auto font-medium text-white">
            Clairifi Security
          </span>
        </div>
      </div>
      <div className="bg-slate-900 flex flex-col grow justify-center items-center  h-full w-full absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-1">
          <img
            className="object-cover opacity-60 object-top w-full h-full xl:absolute xl:inset-0 top:0 left-0 right-0 bottom-0"
            src={HeroImage}
            alt=""
          />
          <div className="absolute inset-0 h-full w-full" />
        </div>
      </div>
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            <div className="rounded-lg mx-auto bg-white pb-12 max-w-2/3 px-6">
              <div className="flex max-w-sm mx-auto py-4 ">
                <span className="flex items-center text-3xl font-normal text-slate-700">
                  <img width="40" className="mx-auto " src={Logo} />
                  Clairifi Security
                </span>
              </div>
              <div className="flex max-w-sm mx-auto px-4">
                {/* <img width="80" className="mx-auto px-4 py-8" src={Logo} /> */}
                {pageLoad && (
                  <span className="inline-flex text-xl m-auto  text-slate-700">
                    Verifying your account
                  </span>
                )}
              </div>
              {tokenErr ? (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  {tokenErrMessage}
                </div>
              ) : (
                <div className="max-w-sm mx-auto px-4 ">
                  {pageLoad ? (
                    <div class="flex justify-center items-center">
                      <img
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                        src={Spinner}
                        width="32"
                        height="32"
                        alt="loading.."
                      />
                    </div>
                  ) : (
                    <>
                      <h1 className="text-lg text-slate-700 font-medium mb-6">
                        Create a new password
                      </h1>
                      {/* Form */}
                      <form onSubmit={resetPassword}>
                        <div className="space-y-4">
                          <div>
                            <label
                              className="block text-sm font-medium mb-1"
                              htmlFor="password"
                            >
                              New Password
                            </label>
                            <input
                              id="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="form-input rounded-md w-full 
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-brand-500 
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-none focus:ring-1 focus:ring-brand-500"
                              type="password"
                              autoComplete="on"
                            />
                          </div>
                          <div>
                            <label
                              className="block text-sm font-medium mb-1"
                              htmlFor="password"
                            >
                              Retype New Password
                            </label>
                            <input
                              id="confirm-password"
                              className="form-input rounded-md w-full 
                      focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-brand-500 
                      border-gray-300 py-2 px-3 shadow-sm
                      hover:border-gray-400
                      focus:border-none focus:ring-1 focus:ring-brand-500"
                              type="password"
                              autoComplete="on"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex items-center justify-between mt-6">
                          {loader ? (
                            <div class="flex justify-center items-center">
                              <img
                                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                                src={Spinner}
                                width="32"
                                height="32"
                                alt="loading.."
                              />
                            </div>
                          ) : (
                            <button
                              type="submit"
                              class="btn btn-lg w-full bg-secondary-600 hover:bg-secondary-700 hover:shadow-sm text-white "
                              // onClick={auth}
                            >
                              Set Password
                            </button>
                          )}
                        </div>
                      </form>
                    </>
                  )}

                  {err && (
                    <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                      The passwords you entered do not match. Please try again.
                    </div>
                  )}
                  {/* Footer */}
                  {isAuthenticated && (
                    <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                      Your link has expired. Please contact your administrator.
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
