import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const _user = {
  getUserPanelTabs(group) {
    const userPanelTabs = [
      {
        name: "My team",
        group: "team",
        href: "#",
        current: true,
        view: ["Super Admin", "Administrator", "Manager"],
      },
      {
        name: "Company Users",
        group: "company",
        href: "#",
        current: false,
        view: ["Super Admin", "Administrator"],
      },
      {
        name: "All system users",
        group: "system",
        href: "#",
        current: false,
        view: ["Super Admin"],
      },
      {
        name: "Add Users",
        group: "team",
        href: "#",
        current: false,
        view: ["Super Admin", "Administrator", "Manager"],
      }, //company, system, or tenant admin only
      {
        name: "Create Tenant",
        group: "company",
        href: "#",
        current: false,
        view: ["Super Admin", "Administrator"], //company or system admin only
      },
      {
        name: "Create Company",
        group: "system",
        href: "#",
        current: false,
        view: ["Super Admin"],
      }, //system admin only
      //need to update the form for this
    ];
    let returnPanels = [];
    userPanelTabs.map((item) => {
      if (item.view.includes(group)) {
        returnPanels.push(item);
      }
    });

    return returnPanels;
  },
  async bulkUserUpdate(csv, type) {
    let profile = _auth.getUserProfile();
    try {
      console.log(csv, "csv");
      const data = new FormData();

      data.append("file", csv);
      data.append("companyId", profile.tenantCompanyId);
      data.append("company", profile.company);
      data.append("tenantId", profile.tenantId);
      data.append("type", type);
      let response = await postRequest("create-bulk-user", data);
      if (response.data) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  },
  async addSingleUser(data) {
    //call the same api for adding a single user and creating a tenant
    //addUserToTenant or newCompanyAndTenant

    console.info("COLLAB Add single user with tenant id", data);
    try {
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response;
      if (data.from === "createNewTenant") {
        console.log("invite-and-create-tenant");
        response = await postRequest("invite-and-create-tenant", data);
      } else if (data.from === "createNewCompanyAndTenant") {
        console.log("invite-and-create-company");
        response = await postRequest("invite-and-create-company", data);
      } else {
        console.log("new-single-user");
        response = await postRequest("new-single-user", data);
      }
      if (response) {
        console.log("AddSingleUser response", response);
      }
      if (response.status === 200) {
        console.info("Response Returned", response);
        return response;
      } else {
        console.info("Response Returned", response);
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  },
  async createNewTenant(data) {
    //call the same api for adding a single user and creating a tenant
    //addUserToTenant or newCompanyAndTenant

    console.info("COLLAB Add single user with tenant id", data);
    // try {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response;
    if (data.from === "createNewTenant") {
      console.log("invite-and-create-tenant");
      response = await postRequest("invite-and-create-tenant", data);
    } else if (data.from === "createNewCompanyAndTenant") {
      console.log("invite-and-create-company");
      response = await postRequest("invite-and-create-company", data);
    } else {
      console.log("new-single-user");
      response = await postRequest("new-single-user", data);
    }
    if (response) {
      console.log("AddSingleUser response", response);
    }
    if (response.status === 200) {
      console.info("Response Returned", response);
      return response;
    } else {
      console.info("Response Returned", response);
      return response;
    }
    // } catch (e) {
    //   console.log(e);
    // }
  },
  async bulkUserUpdate(csv, type) {
    let profile = _auth.getUserProfile();
    try {
      console.log(csv, "csv");
      const data = new FormData();

      data.append("file", csv);
      data.append("companyId", profile.tenantCompanyId);
      data.append("company", profile.company);
      data.append("tenantId", profile.tenantId);
      data.append("type", type);
      data.append("createCompany", false);
      data.append("createTenant", true);
      let response = await postRequest("create-bulk-user", data);
      if (response.data) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  },
  async createOnBoard(data) {
    try {
      let response = await postRequest("/on-board");
      if (response.data) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getUsers(scope, page) {
    try {
      let token = _auth.getUserToken("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await getRequest(`get-all-users`, {
        params: {
          page: page,
          tenantId: _auth.getUserProfile().tenantId,
          companyId: _auth.getUserProfile().tenantCompanyId,
          scope: scope,
        },
      });

      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async getAllUser(page, scope) {
    try {
      let token = _auth.getUserToken("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await getRequest(`get-all-users`, {
        params: {
          page: page,
          tenantId: _auth.getUserProfile().tenantId,
        },
      });
      ////debugger;
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async getTenantUsers(page) {
    try {
      let token = _auth.getUserToken("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await getRequest(`get-tenant-users`, {
        params: {
          page: page,
          tenantId: _auth.getUserProfile().tenantId,
        },
      });
      ////debugger;
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async disableUser(id, page, scope) {
    try {
      let token = _auth.getUserToken("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("disable-user", {
        user_id: id,
      });
      // //debugger;
      if (response.data) {
        const allusers = this.getUsers(scope, page);
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async enableUser(id, page, scope) {
    try {
      let token = _auth.getUserToken("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("enable-user", {
        user_id: id,
      });
      // //debugger;
      if (response.data) {
        const allusers = this.getUsers(scope, page);
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  canBeReinvited(dt) {
    let date = new Date(dt);
    let today = new Date();
    let diff = Math.abs(today - date);
    let diffHours = Math.ceil(diff / (1000 * 60 * 60));
    console.info("diff", diffHours);
    if (diffHours > 24) {
      return true;
    } else {
      return false;
    }
  },

  async reinviteUser(id, page, scope) {
    try {
      let token = _auth.getUserToken("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("resend-invite", {
        user_id: id,
      });
      ////debugger;
      if (response) {
        return true;
      } else {
        return response;
      }
      // if (response.data) {
      //   return;
      // } else {
      //   return [];
      // }
    } catch (e) {
      return e;
    }
  },
  async deleteUserFromTenant(data, page, scope) {
    try {
      let token = _auth.getUserToken("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("delete-user-tenant", {
        user_id: data.id,
        tenantId: data.tenantId,
      });
      // //debugger;
      if (response.data) {
        const allusers = this.getUsers(scope, page);
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async updateUserStatus(data, status, page, scope) {
    try {
      let token = _auth.getUserToken("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("update-user-status", {
        user_id: data.id,
        tenantId: data.tenantId,
        status: status,
      });
      // //debugger;
      if (response.data) {
        const allusers = this.getUsers(scope, page);
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async renameTenant(data, key) {
    let profile = _auth.getUserProfile();
    console.log("ONBOARD rename tenant api", profile.tenantId, data, key);

    let body = {
      tenantName: data,
      tenantId: profile.tenantId,
      key: key,
    };
    // try {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("rename-tenant", body);
    if (response.data) {
      return response.data;
    } else {
      return false;
    }
    // } catch (e) {
    //   return e;
    // }
  },
  async createTenant(data) {
    let profile = _auth.getUserProfile();
    console.log("create tenant", profile);

    let body = {
      tenantName: data,
      tenantCompany: profile.company,
      companyName: profile.company,
      companyId: profile.tenantCompanyId,
      role: "Manager",
      tenantCompanyID: profile.tenantCompanyId,
      createdBy: profile.name,
      createdById: profile.user_id,
      key: data.key,
      teamId: data._id,
    };
    // try {
    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("create-tenant", body);
    if (response.data.data) {
      return response.data.data.data;
    } else {
      return false;
    }
    // } catch (e) {
    //   return e;
    // }
  },
  async getUserByEmail(email) {
    try {
      let token = _auth.getUserToken("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest(`users/get-user-by-email`, {
        email: email,
      });
      console.log("users/get-user-by-email`", response.data.data.data);
      ////debugger;
      if (response.data) {
        return response.data.data.data;
      } else {
        return {};
      }
    } catch (e) {
      return e;
    }
  },
};
