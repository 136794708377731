import React from "react";
import HeroImage from "../images/login-bg.webp";
import Logo from "../images/logo.webp.png";

const LoginBackground = ({ children }) => {
  return (
    <div>
      <div className="absolute opacity-80 top-0 z-40 bg-none max-w-sm px-4 py-3">
        <div className="flex flex-row justify-center content-center">
          {/* <img width="80" className="mr-3 " src={Logo} /> */}
          <span className="inline-flex text-2xl m-auto font-medium text-white">
            Clairifi Security
          </span>
        </div>
      </div>
      <div className="bg-slate-900 flex flex-col grow justify-center items-center  h-full w-full absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-1">
          <img
            className="object-cover opacity-60 object-top w-full h-full xl:absolute xl:inset-0 top:0 left-0 right-0 bottom-0"
            src={HeroImage}
            alt=""
          />
          <div className="absolute inset-0 h-full w-full" />
        </div>
      </div>
    </div>
  );
};

export default LoginBackground;
