import setAuthToken from "../config/setAuthToken";
import { getRequest, postRequest } from "./ApiRequest";

import { _auth } from "./_auth";
{
  /*
Route.post("/comments/add", auth, (req, res) =>
  comments_controller.addComment(req, res)
);
Route.get("/comments/get", auth, (req, res) =>
  comments_controller.getComments(req, res)
);
Route.post("/comments/delete", auth, (req, res) =>
  comments_controller.deleteComment(req, res)
);
Route.post("/comments/edit", auth, (req, res) =>
  comments_controller.editComment(req, res)
);
Route.post("/comments/addReply", auth, (req, res) =>
  comments_controller.addReply(req, res)
);
Route.post("/comments/addThanks", auth, (req, res) =>
  comments_controller.addThanks(req, res)
);
Route.post("/comments/removeThanks", auth, (req, res) =>
  comments_controller.removeThanks(req, res)
);
Route.post("/comments/addReplyThanks", auth, (req, res) =>
  comments_controller.addReplyThanks(req, res)
);
Route.post("/comments/removeReplyThanks", auth, (req, res) =>
  comments_controller.removeReplyThanks(req, res)
);
Route.post("/comments/getReplyCountByComment", auth, (req, res) =>
  comments_controller.getReplyCountByComment(req, res)
);

Route.post("/comments/getThanksCountByComment", auth, (req, res) =>
  comments_controller.getThanksCountByComment(req, res)
);

Route.post("/comments/getThanksCountByReply", auth, (req, res) =>
  comments_controller.getThanksCountByReply(req, res)
);
*/
}

export const _comments = {
  async addComment(impedimentId, comment) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
        comment: comment,
        impedimentId: impedimentId,
        userId: profile.user_id,
        name: profile.name,
      };

      let response = await postRequest("comments/add", data);
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getCommentsByImpediment(impedimentId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
        impedimentId: impedimentId,
      };

      let response = await postRequest(
        "/comments/getCommentsByImpediment",
        data
      );
      if (response && response.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async deleteComment(commentId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/delete", data);
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async editComment(commentId, comment) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        comment: comment,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/edit", data);
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async addReply(commentId, reply) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        reply: reply,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/addReply", data);
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async addThanks(commentId, replyId, reply) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        replyId: replyId,
        reply: reply,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/addThanks", data);
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async removeThanks(commentId, replyId, reply) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        replyId: replyId,
        reply: reply,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/removeThanks", data);
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async addReplyThanks(commentId, replyId, reply) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let data = {
        commentId: commentId,
        replyId: replyId,
        reply: reply,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/addReplyThanks", data);
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async removeReplyThanks(commentId, replyId, reply) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        commentId: commentId,
        replyId: replyId,
        reply: reply,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/removeReplyThanks", data);
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  },
  async getCommentCountByImpediment(impedimentId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        impedimentId: impedimentId,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest(
        "/comments/getCommentCountByImpediment",
        data
      );
      if (response && response.data) {
        return response.data.data.count;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  },
  async getReplyCountByComment(commentId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        commentId: commentId,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest(
        "/comments/getReplyCountByComment",
        data
      );
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  },
  async getThanksCountByComment(commentId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        commentId: commentId,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest(
        "/comments/getThanksCountByComment",
        data
      );
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  },
  async getThanksCountByReply(replyId) {
    try {
      let profile = _auth.getUserProfile();
      let token = _auth.getUserToken("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));

      let data = {
        replyId: replyId,
        tenantId: profile.tenantId,
        teamId: profile.teamId ? profile.teamId : profile.tenantId,
      };

      let response = await postRequest("/comments/getThanksCountByReply", data);
      if (response && response.data) {
        return response.data.data.data;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  },
};
