import React from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Logo from "../images/logo.webp.png";
import HeroImage from "../images/login-bg.webp";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import { usePersistStore } from "../store/usestore";
import Input from "../components/Input";

function ForgotPassword() {
  const mvp = false;
  const history = useHistory();
  // const [isAuthenticated, setIsAuthenticated] = useState();
  const [passwordMatched, isPasswordMatched] = useState(true);
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const { isAuthenticated, isSuccess, loginError, resetPasswordAsync } =
    usePersistStore();
  const resetPassword = async (event) => {
    setLoader(true);
    event.preventDefault();

    let path = "/home";

    if (password !== confirmPassword) {
      isPasswordMatched(false);
    }

    // let profile = await _auth.resetPassword(otp,password,confirmPassword,history)
    resetPasswordAsync(otp, password, confirmPassword, history);

    if (isSuccess === false) {
      setLoader(false);
      // setIsAuthenticated(false)
    } else {
      setLoader(false);
    }
  };

  return (
    <main className="">
      <div className="absolute opacity-80 top-0 z-40 bg-none max-w-sm px-4 py-3">
        <div className="flex flex-row justify-center content-center">
          {/* <img width="80" className="mr-3 " src={Logo} /> */}
          <span className="inline-flex text-2xl m-auto font-medium text-white">
            Clairifi Security
          </span>
        </div>
      </div>
      <div className="bg-slate-900 flex flex-col grow justify-center items-center  h-full w-full absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-1">
          <img
            className="object-cover opacity-60 object-top w-full h-full xl:absolute xl:inset-0 top:0 left-0 right-0 bottom-0"
            src={HeroImage}
            alt=""
          />
          <div className="absolute inset-0 h-full w-full" />
        </div>
      </div>
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen  flex flex-col content-center justify-center">
            <div className="rounded-lg m-auto mx-auto bg-white pb-12 max-w-2/3 px-6">
              <div className="flex max-w-sm mx-auto py-4 ">
                <span className="flex items-center text-3xl font-normal text-slate-700">
                  <img width="40" className="mx-auto " src={Logo} />
                  Clairifi Security
                </span>
              </div>

              <div className="max-w-sm mx-auto px-4 ">
                <h1 className="text-2xl text-slate-800 font-medium mb-6">
                  Reset Password
                </h1>
                {/* Form */}
                <form onSubmit={resetPassword}>
                  <div className="space-y-4">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        Verification Code
                      </label>
                      <Input
                        id="Otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="form-input w-full"
                        type="number"
                        autoComplete="on"
                      />
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        New Password
                      </label>
                      <Input
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-input w-full"
                        type="password"
                        autoComplete="on"
                      />
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        Retype New Password
                      </label>
                      <Input
                        id="confirm-password"
                        className="form-input w-full"
                        type="password"
                        autoComplete="on"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    {loader ? (
                      <div class="flex justify-center items-center">
                        <img
                          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                          src={Spinner}
                          width="32"
                          height="32"
                          alt="loading.."
                        />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        class="btn bg-secondary-700 hover:bg-secondary-600 text-white w-full"
                        // onClick={auth}
                      >
                        Reset Password
                      </button>
                    )}

                    {/* After login and MFA, check to see if onboarding has completed or 
            if the user has checked the "Don't show this wizard again" checkbox.
            If the user has not checked the do not show again box and has no completed onboarding, redirect to the onboarding page.
             */}
                    {/* <Link
                    className="btn bg-primary-500 hover:bg-primary-600 text-white ml-3"
                    to="/onboard"
                  >
                    Sign In
                  </Link> */}
                  </div>
                </form>

                {/* Footer */}
                {passwordMatched === false && (
                  <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                    Please recheck your password.
                  </div>
                )}
                {loginError === true && (
                  <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                    Username or password is incorrect.
                  </div>
                )}
                {/* <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{" "}
                  <Link
                    className="font-medium text-primary-500 hover:text-primary-600"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ForgotPassword;
