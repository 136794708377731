import React, { FC } from "react";

// import { IconType, IconPosition } from "./Types";
// import { Typography, TypographyVariant } from "./Typography";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

// interface Props {
//   extends React.InputHTMLAttributes<HTMLInputElement> {
//   placeholder: string;
//   Icon?: IconType;
//   iconPosition?: IconPosition;
//   label?: string;
//   id: string;
//   helpText?: string;
//   errorText?: string;
// }

export default function Input(props, ...rest) {
  const {
    placeholder,
    Icon,
    iconPosition,
    label,
    id,
    type,
    helpText,
    errorText,
  } = props;
  let inputClassName = `appearance-none block w-full px-3 py-2 border border-gray-300 
  rounded-md shadow-sm placeholder-gray-400 
  focus:outline-none focus:border-1 focus:border-accent-500  
  focus:ring-0
  sm:text-sm 
  hover:border-1 hover:border-secondary-500  sm:text-sm hover:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] hover:shadow-accent-500`;

  if (errorText) {
    inputClassName =
      "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:border-secondary-500  sm:text-sm";
  }

  return (
    <div>
      {/* {label ? <p>{label}</p> : ""} */}

      <div className="mt-1 relative rounded-md">
        <input
          name={id}
          id={id}
          type={type}
          className={inputClassName}
          defaultValue={props.defaultValue}
          placeholder={placeholder}
          {...rest}
          onChange={(e) => props.onChange(e)}
        />
        {helpText && !errorText ? <p className="mt-2">{helpText}</p> : ""}
        {errorText ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {errorText ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorText}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

Input.defaultProps = {
  label: "Input",
  disabled: false,
  //   iconPosition: IconPosition.LEADING,
};
