import setAuthToken from "../config/setAuthToken";
import { _auth } from "./_auth";

const { postRequest, getRequest } = require("./ApiRequest");

export const _value_drivers = {
  async getValueDrivers() {
    let profile = _auth.getUserProfile();

    let data;
    data = {
      companyId: profile.tenantCompanyId,
    };

    let token = _auth.getUserToken("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let response = await postRequest("valuedrivers/get-value-drivers", data);

    if (response) {
      return response.data.data;
    } else {
      return [];
    }
  },
};
