import React from "react";
import Button from "../components/Button";
import { Icon } from "@iconify/react";

const CenterPageWithButton = (props) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="col-span-6  flex items-center justify-center min-h-full h-full">
          <div className="text-center mt-20">
            {
              <div
                id="save_project"
                className="flex flex-col items-center justify-center space-y-8"
              >
                <div className="mx-auto flex items-center justify-center">
                  {props.icon}
                  <h3 className="mt-2 ml-2 text-2xl font-semibold text-gray-900">
                    {props.title}
                  </h3>
                </div>
                <div className="mt-1 text-md text-gray-500 w-3/4 text-center flex items-center justify-center">
                  {props.description}
                </div>
                {props.body && (
                  <div className="mt-1 text-md text-gray-500 w-3/4 text-center flex items-center justify-center">
                    {props.body}
                  </div>
                )}
                <div className="mt-6 space-x-6">
                  {/* <Button color="text">Cancel</Button> */}
                  {props.showButton && (
                    <Button
                      handleClick={() => props.onClick()}
                      color={props.buttonColor || "secondary"}
                    >
                      {props.buttonText}
                    </Button>
                  )}
                  {props.showSecondButton && (
                    <Button
                      handleClick={(e) => props.secondButtonOnClick(e)}
                      color={props.secondButtonColor}
                    >
                      {props.secondButtonText}
                    </Button>
                  )}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterPageWithButton;
