import React, { useEffect, useState } from "react";
import PricingTiers from "../../components/PricingTiers";
import Button from "../../components/Button";
import SubscriptionListItem from "../../components/SubscriptionListItem";
import Fade from "react-reveal/Fade";
import { Icon } from "@iconify/react";
import CenterPageWithButton from "../../components/CenterPageWithButton";
import ModalBasic from "../../components/ModalBasic";
import { compare } from "semver";
import { set } from "date-fns";
import { current } from "tailwindcss/colors";
import StackedList from "../../components/StackedList";
import StackedListButtons from "../../components/StackedListButtons";
import PreferenceList from "../../components/Preferences/PreferenceList";
import PreferenceListItem from "../../components/Preferences/PreferencesListItem";
import { _stripe } from "../../modules/_stripe";
import OrderSummary from "./OrderSummary";
import { utils } from "../../modules/_utils";
function PlansPanel({ stripeData, setStripeData }) {
  const [annual, setAnnual] = useState(true);
  const [currentStep, setCurrentStep] = useState("default");
  const [fadeDirection, setFadeDirection] = useState("right");
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [freeTier, setFreeTier] = useState({});
  const [selectedTier, setSelectedTier] = useState({});
  const [selectedProductId, setSelectedProductId] = useState();
  const [selectedPriceId, setSelectedPriceId] = useState();
  const [selectedFrequency, setSelectedFrequency] = useState("annual");
  const [addons, setAddons] = useState([]);
  const [myTier, setMyTier] = useState({});
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [compareModalOpen, setCompareModalOpen] = useState(true);
  const [placingOrder, setPlacingOrder] = useState(false);

  useEffect(() => {
    (async () => {
      let allProducts = await _stripe.getAvailableStripeProductsAndPrices(
        "tier"
      );
      console.log("All products before filter", allProducts);
      //remove the free tier from the list of tiers
      let allProductsFiltered = allProducts.filter(
        (tier) => tier.metadata?.tier !== "free"
      );

      setTiers(allProductsFiltered);
      //find the free tier and set it as the free tier
      let freeTier = allProducts.filter(
        (tier) => tier.metadata?.tier === "free"
      )[0];
      console.log("Free Tier", freeTier);
      //set the free tier
      setFreeTier(freeTier);

      console.log("All Products", allProducts);
    })();
  }, []);

  useEffect(() => {
    //setSelectedItems to the most popular tier
    const myTier = _stripe.getMyTier(stripeData.subscription.items);
    setMyTier(myTier);
    console.log("My Tier", myTier);
    const myAddons = _stripe.getMyAddons(stripeData.subscription.items);
    const myAddonIds = myAddons.map((addon) => addon.priceId);
    console.log("My addons", myAddons);
    console.log("My AddonIds", myAddonIds);
    setSelectedAddons(myAddonIds);
    //get the tier from tiers that has the same product id as my tier
    const selectedTier = tiers.filter(
      (tier) => tier.id === myTier?.productId
    )[0];
    setSelectedTier(selectedTier || tiers[0]);
    setSelectedProductId(myTier?.productId);
    setSelectedPriceId(myTier?.priceId);
    setSelectedFrequency(myTier?.interval);
    console.log(
      "onLoad Selected Tier",
      myTier,
      selectedTier,
      selectedProductId,
      myTier
    );
    // setSelectedTier(tiers.filter((tier) => tier.mostPopular)[0] || tiers[0]);
  }, [tiers, stripeData]);

  //if I select a tier that does not have addons, remove setSelectedAddons to an empty array
  useEffect(() => {
    if (selectedTier && selectedTier.addons) {
      setSelectedAddons([]);
    }
  }, [selectedTier]);

  useEffect(() => {
    console.log("handleSelectTier Selected Tier", selectedTier);
    console.log("handleSelectTier Selected Addons", selectedAddons);
    console.log("handleSelectTier Selected Price Id", selectedPriceId);
    console.log("handleSelectTier Selected Frequency", selectedFrequency);
  }, [selectedTier, selectedAddons]);

  const handleBackClick = () => {
    setIsBackClicked(true);
  };
  const getProductPrice = (product, frequency) => {
    // Find the price that matches the selected frequency
    const priceObj = product.prices.filter(
      (price) => price.recurrence === frequency
    );

    // If a matching price is found, return it. Otherwise, return null.
    return priceObj.length > 0 ? priceObj[0].cost : null;
  };

  const getProductPriceId = (product, frequency) => {
    // Find the price that matches the selected frequency
    const priceObj = product.prices.filter(
      (price) => price.recurrence === frequency
    );

    // If a matching price is found, return it. Otherwise, return null.
    return priceObj.length > 0 ? priceObj[0].id : null;
  };

  const getProductByPriceId = (product, priceId) => {
    //find the product that has the priceId
    const priceObj = product.prices.filter((price) => price.id === priceId);
    return priceObj.length > 0 ? priceObj[0] : null;
  };

  const handlePlaceOrderClick = async (e) => {
    setPlacingOrder(true);
    console.log("handlePlaceOrderClick", selectedTier, selectedAddons);
    let data = {
      subscriptionId: stripeData.subscriptionId,
      currentItems: stripeData.subscription.items,
      items: [
        {
          price: selectedPriceId,
        },
      ],
    };
    selectedAddons.map((addon) =>
      data.items.push({
        price: addon,
      })
    );

    console.log("handlePlaceOrderClick Data", data);
    let response = await _stripe.updateSubscription(data);
    console.log("handlePlaceOrderClick Response", response);
    if (response) {
      await _stripe.getStripeData();
      let stripeData = await utils.getCacheData("fullStripeData", "Stripe");
      setStripeData(stripeData);
      setIsBackClicked(false);
      setCurrentStep("confirmation");
      setPlacingOrder(false);
    } else {
      setPlacingOrder(false);
      console.log("handlePlaceOrderClick Error", response);
    }
  };

  const handleSelectTier = (freq, tier) => {
    setSelectedTier(tier);
    setSelectedProductId(tier.id);
    setSelectedFrequency(freq.stripe);
    setAddons(tier.addons);
    console.log("handleSelectTier addons", tier.addons);
    //find the price that has the same frequency as the selected frequency
    let price = tier.prices.filter((price) => price.recurrence === freq.stripe);
    console.log("handleSelectTier Price", price);
    if (price.length > 0) {
      setSelectedPriceId(price[0].id);
    } else {
      console.log(
        `handleSelectTier No price found for frequency ${freq.stripe}`
      );
    }
    console.log(
      "handleSelectTier ALL SELECTED INFO",
      tier.id,
      freq.stripe,
      price
    );
  };

  const handleOtherClick = () => {
    setIsBackClicked(false);
  };
  console.log("Current Step", currentStep);
  return (
    <>
      <div className="grow overflow-hidden w-full h-full">
        {/* Panel body */}

        <div className="pr-4 space-y-6 w-full h-full">
          {currentStep === "default" && (
            // <Fade {...(!isBackClicked ? { right: true } : { left: true })}>
            <section>
              <div className="mb-8">
                <h2 className="text-xl text-slate-800 font-bold mb-4">
                  My Team's Subscriptions
                </h2>
                <div className="text-sm">
                  View or manage your team's subscriptions and add-ons.{" "}
                </div>
              </div>
              {/* <SubscriptionListItem
                icon={
                  <Icon
                    icon="flat-color-icons:process"
                    className=" h-[42px] w-[42px]"
                  />
                }
                itemName="Base Subscription"
                renewalDate="1/2/2025"
                productName="Vendor Standard"
                price="$2000/month"
                numberOfProducts="3 products"
                buttonText="Manage"
                showButton={true}
                buttonIcon=""
                buttonIconSelected="flat-coloricons:template"
                buttonColor="secondary"
                onClick={(e) => {
                  setIsBackClicked(false);
                  setCurrentStep("manage");
                }}
              /> */}
              <SubscriptionListItem
                icon={
                  <Icon
                    icon="flat-color-icons:process"
                    className=" h-[42px] w-[42px]"
                  />
                }
                itemName={stripeData.subscription.items[0].productName}
                renewalDate={`$${stripeData.subscription.amount}/${stripeData.subscription.recurringSchedule}`}
                productName={
                  "Renews on"
                  // stripeData.subscription.recurringSchedule ===
                  // "month"
                  //   ? "Monthly"
                  //   : "Annual"
                }
                price={stripeData.subscription.endDate}
                numberOfProducts={`${stripeData.subscription.items.length} products`}
                buttonText="Manage"
                showButton={true}
                buttonIcon=""
                buttonIconSelected="flat-coloricons:template"
                buttonColor="secondary"
                onClick={(e) => {
                  setIsBackClicked(false);
                  setCurrentStep("manage");
                }}
              />

              {/* <div>{JSON.stringify(stripeData)}</div> */}
            </section>
            // </Fade>
          )}

          {currentStep === "manage" && (
            <Fade {...(!isBackClicked ? { right: true } : { left: true })}>
              <div className="mb-0 w-full flex justify-between">
                <div>
                  <h2 className="text-xl text-slate-800 font-bold mb-4">
                    Manage your plan
                  </h2>
                  <div className="text-sm">
                    View and manage your existing plan.
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <Button
                    handleClick={(e) => {
                      setIsBackClicked(true);
                      setCurrentStep("default");
                    }}
                    color="text"
                  >
                    Back
                  </Button>
                  {/* <Button
                    handleClick={(e) => {
                      setIsBackClicked(false);
                      setCurrentStep("select-plan");
                    }}
                    color="secondary"
                  >
                    Hide this button
                  </Button> */}
                </div>
              </div>
              <div className="flex  w-full"></div>
              <div className="flex flex-col h-full w-full space-y-4 ">
                <div className="flex justify-around w-full h-full">
                  <div className="h-full flex flex-1 flex-col border-r border-gray-200">
                    <div>
                      <h2 className="text-lg text-slate-800 font-medium mb-4">
                        Details
                      </h2>
                    </div>
                    <div className="mx-4 space-y-4 text-md">
                      <div className="">
                        Plan type:{" "}
                        {stripeData.subscription.recurringSchedule === "month"
                          ? "Monthly"
                          : "Annual"}
                      </div>
                      <div className="">
                        Price: ${stripeData.subscription.amount}/
                        {stripeData.subscription.recurringSchedule}
                      </div>
                      <div className="">
                        Auto Renewal:{" "}
                        {stripeData.subscription.autoRenew ? "On" : "Off"}
                      </div>
                      <div className="text-md">
                        {stripeData.subscription.autoRenew
                          ? "Renews On"
                          : "Current period"}{" "}
                        {stripeData.subscription.startDate} -{" "}
                        {stripeData.subscription.endDate}
                      </div>
                      <div className="">
                        Included Products:
                        {stripeData.subscription.items.map((item) => (
                          <div>
                            {item.productName}- ${item.amount}/{item.interval}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="mx-4  flex flex-1 flex-col w-full  gap-x-4 gap-y-4">
                    <div className="mb-8">
                      <h2 className="text-lg text-slate-800 font-medium mb-4">
                        Take Action
                      </h2>
                      <div className="text-sm">
                        Use the buttons below to manage your subscription.
                      </div>
                    </div>
                    <div className="flex flex-col items-center space-y-4">
                      <StackedListButtons
                        headingText="Let's get you started."
                        headingSubtext="Here are some things you can do to get started. If you need help, feel free to reach out to us!"
                        // handleOpenAssessmentModalFromHome={(e) => {
                        //   console.log("onclick called", e);
                        //   props.handleOpenAssessmentModalFromHome(e);
                        // }}
                        // setNewSolicitationShortcutModalOpen={(e) => {
                        //   console.log("onclick called", e);
                        //   setNewSolicitationShortcutModalOpen(true);
                        // }}
                        options={[
                          {
                            id: "2",
                            status: "Active",
                            title: "Switch plans",
                            subTitle: "Upgrade or downgrade your plan.",
                            rightText: "",
                            highlight: true,
                            handleClick: (e) => {
                              setIsBackClicked(false);
                              setCurrentStep("select-plan");
                            },
                          },
                          {
                            id: "2a",
                            status: "Active",

                            title: "Add/Remove features",

                            subTitle:
                              "Add or remove addons and features from your plan.",
                            rightText: "",
                            highlight: false,
                            handleClick: (e) => {
                              setIsBackClicked(false);
                              setCurrentStep("addons");
                            },
                          },
                          // {
                          //   id: "0",
                          //   status: "Active",
                          //   title: "Mange auto renewal",

                          //   subTitle: "Start or stop auto renewal.",
                          //   rightText: "",
                          //   handleClick: (e) => {
                          //     setIsBackClicked(false);
                          //     setCurrentStep("change-auto-renew");
                          //   },
                          // },
                          // {
                          //   id: "3",
                          //   status: "Active",
                          //   title: "Cancel subscription",
                          //   subTitle: "Cancel your subscription.",
                          //   rightText: "",

                          //   handleClick: (e) => {
                          //     setIsBackClicked(false);
                          //     setCurrentStep("cancel-subscription");
                          //   },
                          // },
                        ]}
                      />
                      {/* <div className="">
                        <Button
                          color="text"
                          handleClick={(e) => {
                            setIsBackClicked(false);
                            setCurrentStep("cancel-subscription");
                          }}
                        >
                          Cancel subscription
                        </Button>
                      </div> */}
                      {/* <div>
                        {" "}
                        <Button
                          color="outline"
                          handleClick={(e) => {
                            setIsBackClicked(false);
                            setCurrentStep("change-auto-renew");
                          }}
                        >
                          Change auto renewal
                        </Button>
                      </div> */}
                      {/* <div>
                        {" "}
                        <Button
                          color="secondary"
                          handleClick={(e) => {
                            setIsBackClicked(false);
                            setCurrentStep("select-plan");
                          }}
                        >
                          Manage Features
                        </Button>
                      </div> */}
                      {/* <div>
                     
                        <Button
                          color="emerald"
                          handleClick={(e) => {
                            setIsBackClicked(false);
                            setCurrentStep("select-plan");
                          }}
                        >
                          Modify Plan/Upgrade
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          )}
          {currentStep === "change-auto-renew" && (
            <Fade {...(!isBackClicked ? { right: true } : { left: true })}>
              <div className="mb-0 flex justify-between">
                <div>
                  <h2 className="text-xl text-slate-800 font-bold mb-4">
                    Auto renewal
                  </h2>
                  <div className="text-sm">
                    Choose whether or not your subscription auto renews at the
                    end of the billing cycle.
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <Button
                    handleClick={(e) => {
                      setIsBackClicked(true);
                      setCurrentStep("manage");
                    }}
                    color="text"
                  >
                    Back
                  </Button>
                  {/* <Button
                    handleClick={(e) => {
                      setIsBackClicked(false);
                      setCurrentStep("select-plan");
                    }}
                    color="secondary"
                  >
                    Hide this button
                  </Button> */}
                </div>
              </div>
              <div className="flex w-full justify-end"></div>
              <PreferenceListItem
                item={{
                  id: "auto-renew",
                  value: true,
                  setValue: (v) => console.log(v),
                  title: "Auto Renew",
                  description: "Enable auto renewal for your subscription.",
                }}
              />
              {/* <div>Change Plan</div>
              <div>Cancel Subscription</div> */}
            </Fade>
          )}
          {currentStep === "cancel-subscription" && (
            <Fade {...(!isBackClicked ? { right: true } : { left: true })}>
              <div className="mb-8 flex justify-between">
                <div>
                  <h2 className="text-xl text-slate-800 font-bold mb-4">
                    Cancel Subscription
                  </h2>
                  <div className="text-sm">
                    {/* Congratulations on your new subscription! */}
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  {/* <Button
              handleClick={(e) => setCurrentStep("default")}
              color="outline"
            >
              Done
            </Button> */}
                </div>
              </div>

              <CenterPageWithButton
                icon={
                  <Icon
                    icon="flat-color-icons:cancel"
                    className="flex h-[42px] w-[42px]"
                  />
                }
                title="Cancel your subscription"
                description="By continuing, your subscription will be canceled at the end of your billing period and you will no longer have access to any premium features or addon features. You can subscribe again at any time."
                showButton={true}
                buttonColor="text"
                buttonText="Cancel my subscription"
                onClick={(e) => setCurrentStep("cancel-confirm")}
                showSecondButton={true}
                secondButtonText="Keep my subscription"
                secondButtonColor="secondary"
                secondButtonOnClick={(e) => setCurrentStep("manage")}
              />
            </Fade>
          )}
          {currentStep === "cancel-confirm" && (
            <Fade {...(!isBackClicked ? { right: true } : { left: true })}>
              <div className="mb-8 flex justify-between">
                Waiting to cancel...
              </div>
            </Fade>
          )}

          {currentStep === "select-plan" && (
            <Fade {...(!isBackClicked ? { right: true } : { left: true })}>
              <div className="mb-0 flex justify-between">
                <div>
                  <h2 className="text-xl text-slate-800 font-bold mb-4">
                    Select a Plan
                  </h2>
                  <div className="text-sm">
                    Choose a plan for your workspace. You can change plans at
                    any time.
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <Button
                    handleClick={(e) => {
                      setIsBackClicked(true);
                      setCurrentStep("manage");
                    }}
                    color="text"
                  >
                    Back
                  </Button>
                  <Button
                    handleClick={(e) => {
                      setIsBackClicked(false);
                      setCurrentStep("addons");
                    }}
                    color="secondary"
                  >
                    Select this plan
                  </Button>
                </div>
              </div>
              <div className="flex w-full justify-end"></div>
              <PricingTiers
                freeTier={freeTier}
                setCurrentStep={setCurrentStep}
                setIsBackClicked={(v) => setIsBackClicked(v)}
                selectTier={true}
                selectedTier={selectedTier}
                selectedPriceId={selectedPriceId}
                selectedProductId={selectedProductId}
                setSelectedPriceId={(id) => setSelectedPriceId(id)}
                selectedFrequency={selectedFrequency}
                setSelectedFrequency={(freq) => setSelectedFrequency(freq)}
                tiers={tiers}
                setSelectedTier={(freq, tier) => {
                  handleSelectTier(freq, tier);
                }}
              />
            </Fade>
          )}
          {currentStep === "compare-plans" && (
            <Fade {...(!isBackClicked ? { right: true } : { left: true })}>
              <div className="h-full overflow-y-auto scrollbar-default mb-20">
                <div className="mb-2 flex justify-between">
                  <div>
                    <h2 className="text-xl text-slate-800 font-bold mb-4">
                      Compare Plans
                    </h2>
                    <div className="text-sm">
                      Compare the features of each plan to find the best fit.
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    {/* <Button
                    handleClick={(e) => {
                      setIsBackClicked(true);
                      setCurrentStep("manage");
                    }}
                    color="text"
                  >
                    Back
                  </Button> */}
                  </div>
                </div>
                <PricingTiers
                  freeTier={freeTier}
                  setCurrentStep={setCurrentStep}
                  setIsBackClicked={(v) => setIsBackClicked(v)}
                  selectTier={false}
                  selectedTier={selectedTier}
                  selectedPriceId={selectedPriceId}
                  selectedProductId={selectedProductId}
                  setSelectedPriceId={(id) => setSelectedPriceId(id)}
                  selectedFrequency={selectedFrequency}
                  setSelectedFrequency={(freq) => setSelectedFrequency(freq)}
                  tiers={tiers}
                  setSelectedTier={(freq, tier) => {
                    handleSelectTier(freq, tier);
                  }}
                />
              </div>
            </Fade>
          )}
          {currentStep === "addons" && (
            <Fade {...(!isBackClicked ? { right: true } : { left: true })}>
              <div>
                {" "}
                <div className="mb-8 flex justify-between">
                  <div>
                    <h2 className="text-xl text-slate-800 font-bold mb-4">
                      Add-ons
                    </h2>
                    <div className="text-sm">
                      Choose all the add-ons you want to include in your plan.
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <Button
                      handleClick={(e) => {
                        setIsBackClicked(true);
                        setCurrentStep("select-plan");
                      }}
                      color="text"
                    >
                      Back
                    </Button>
                    <Button
                      handleClick={(e) => {
                        setIsBackClicked(false);
                        setCurrentStep("summary");
                      }}
                      color="secondary"
                    >
                      Continue
                    </Button>
                  </div>
                </div>
                {selectedTier &&
                  selectedTier.addons &&
                  selectedTier.addons.map((addon, index) => {
                    console.log("selectedTier.addons.map", addon);
                    return (
                      // <div>test</div>
                      <SubscriptionListItem
                        icon={
                          addon.icon ? (
                            addon.icon
                          ) : (
                            <Icon
                              icon="flat-color-icons:process"
                              className=" h-[42px] w-[42px]"
                            />
                          )
                        }
                        itemName={addon.name}
                        renewalDate={addon.description}
                        productName={`$${getProductPrice(
                          addon,
                          selectedFrequency
                        )}/${selectedFrequency}`}
                        price={""}
                        numberOfProducts=""
                        buttonText={
                          //if the addon is already selected, show "Selected" else show "Select Feature"
                          selectedAddons.includes(
                            getProductPriceId(addon, selectedFrequency)
                          )
                            ? "Added"
                            : "Add Feature"
                        }
                        showButton={true}
                        buttonColor="text"
                        buttonIcon={(() => {
                          if (
                            selectedAddons.includes(
                              getProductPriceId(addon, selectedFrequency)
                            )
                          ) {
                            return (
                              <Icon
                                icon="flat-color-icons:ok"
                                className="h-4 w-4"
                              />
                            );
                          } else {
                            return <Icon icon="mdi:plus" className="h-4 w-4" />;
                          }
                        })()}
                        buttonIconSelected="flat-coloricons:template"
                        onClick={(e) => {
                          console.log("Select Feature");
                          if (
                            selectedAddons.includes(
                              getProductPriceId(addon, selectedFrequency)
                            )
                          ) {
                            setSelectedAddons(
                              selectedAddons.filter(
                                (item) =>
                                  item !==
                                  getProductPriceId(addon, selectedFrequency)
                              )
                            );
                          } else
                            setSelectedAddons([
                              ...selectedAddons,
                              getProductPriceId(addon, selectedFrequency),
                            ]);
                        }}
                      />
                    );
                  })}
              </div>
            </Fade>
          )}
          {currentStep === "feePlanInfo" && "Fee Plan Info"}
          {currentStep === "summary" && (
            <Fade {...(!isBackClicked ? { right: true } : { left: true })}>
              <div className="mb-8 flex justify-between">
                <div>
                  <h2 className="text-xl text-slate-800 font-bold mb-4">
                    Subscription Summary
                  </h2>
                  <div className="text-sm">
                    Below is is a summary of your subscription. Review and
                    confirm.
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <Button
                    handleClick={(e) => {
                      setIsBackClicked(true);
                      setCurrentStep("addons");
                    }}
                    color="outline"
                  >
                    Back
                  </Button>
                  <Button
                    loader={placingOrder}
                    handleClick={(e) => {
                      handlePlaceOrderClick(e);
                    }}
                    color="emerald"
                  >
                    Place Order
                  </Button>
                </div>
              </div>
              <div className="flex flex-col space-y-4 mr-40 ml-20">
                <OrderSummary
                  products={tiers}
                  selectedProductId={selectedProductId}
                  selectedPriceId={selectedPriceId}
                  selectedAddons={selectedAddons}
                  getProductByPriceId={getProductByPriceId}
                  getProductPrice={getProductPrice}
                />
              </div>
              {/* <div className="flex flex-col space-y-4 mr-40 ml-20">
                <div className="flex justify-between">
                  <div className="text-sm">Vendor Standard</div>
                  <div className="text-sm">$2000/month</div>
                </div>
                <div className="flex justify-between">
                  <div className="text-sm">Featured Vendor</div>
                  <div className="text-sm">$2000/month</div>
                </div>
                <div className="flex justify-between">
                  <div className="text-sm">Social Media Boost</div>
                  <div className="text-sm">$2000/month</div>
                </div>
                <div className="flex justify-between">
                  <div className="text-sm">Total</div>
                  <div className="text-sm">$6000/month</div>
                </div>
              </div> */}
            </Fade>
          )}
          {currentStep === "confirmation" && (
            <Fade {...(!isBackClicked ? { right: true } : { left: true })}>
              <div className="mb-8 flex justify-between">
                <div>
                  <h2 className="text-xl text-slate-800 font-bold mb-4">
                    Order Confirmation
                  </h2>
                  <div className="text-sm">
                    {/* Congratulations on your new subscription! */}
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  {/* <Button
                  handleClick={(e) => setCurrentStep("default")}
                  color="outline"
                >
                  Done
                </Button> */}
                </div>
              </div>

              <CenterPageWithButton
                icon={
                  <Icon
                    icon="flat-color-icons:ok"
                    className="flex h-[42px] w-[42px]"
                  />
                }
                title="Success!"
                description="Congratulations on your new subscription!  You may need to log out and log back in to see the changes."
                showButton={true}
                buttonText="Manage My Subscription"
                onClick={(e) => setCurrentStep("default")}
              />
            </Fade>
          )}
        </div>
      </div>
    </>
  );
}

export default PlansPanel;
